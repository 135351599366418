import { createAction } from "@reduxjs/toolkit";

export const fetchCampaignLeadList = createAction(
  "CAMPAIGN_LEAD_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchCampaignBotVoiceList = createAction(
  "CAMPAIGN_BOTVOICE_LIST",
  function prepare(data) {
    return { payload: data };
  }
);