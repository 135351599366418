import { useState } from "react";
import CallIcon from "../../../assets/icons/callIcon";
import Button from "../../../atoms/Button";
import Register from "../../../organisms/auth/Register";
import { pricingSec } from "../HomeConstants";
import styles from "./PricingSec.module.scss";

const PricingSec = () => {
  const [openEarlyAccessModal, setOpenEarlyAccessModal] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  return (
    <div className={` ${styles.pricing}`}>
      <div
        className={` ${styles.pricing__header}`}
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-anchor-placement="bottom-bottom"
      >
        <h2>
          <span> {"Our"}</span> Pricing
        </h2>
        <p>Pricing tailored to everyone, with scaling made easy.</p>
        <div
          className={` ${styles.free}`}
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
        >
          <span>7 days free for general access</span>
        </div>
      </div>

      <div className={` ${styles.pricing__Cards} dflex justifyCenter`}>
        {pricingSec.prices.map((item, index) => (
          <div
            className={` ${styles.pricingCard}`}
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1000"
            key={index}
          >
            <div className={` ${styles.pricingCard__header}`}>
              <h5>{item.title}</h5>
              <p> Starting at</p>
              <div className={` ${styles.price}`}>
                <span>$</span>
                <strong>{item.price}</strong>
                <small>{"/"} month</small>
              </div>
            </div>
            <div className={` ${styles.pricingCard__info}`}>
              <ul>
                {item.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
            {index === 0 ? (
              <div className={` ${styles.pricingCard__btn}`}>
                <Button
                  className="gradient full"
                  label="Start your free trial"
                  action={() => setOpenEarlyAccessModal(true)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
      <div
        className={`dflex alignCenter ${styles.pricing__Credit}`}
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <div className={`dflex alignCenter  ${styles.credit}`}>
          <figure>
            <CallIcon />
          </figure>
          <figcaption>
            <strong>1 credit = 1 property record.</strong> Each property record
            will be called up to 6 times.
          </figcaption>
        </div>
        <div className={` ${styles.skip}`}>
          <p>Skip tracing</p>
          <h3>
            10¢<span>/ skip</span>
          </h3>
        </div>
      </div>
      {openEarlyAccessModal ? (
        // <EarlyAccessSignup
        //   open={openEarlyAccessModal}
        //   setOpen={setOpenEarlyAccessModal}
        // ></EarlyAccessSignup>
        <Register
          open={openEarlyAccessModal}
          setOpen={setOpenEarlyAccessModal}
          openLogin={setOpenLoginModal}
          email={email}
        ></Register>
      ) : (
        ""
      )}
    </div>
  );
};

export default PricingSec;
