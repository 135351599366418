import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import StartCampaign from "./StartCampaign";
import { CampaignSteps } from "./enum";
import { DEFAULT_CAMPAIGN_STEPS_VALUES } from "./constants";
import {
  CampaignFormModel,
  CreateCampaignProps,
  CustomError,
  SelectCampaignFormModel,
  StartCampaignFormModel,
} from "./interface";
import CampaignCreated from "./CampaignCreated";
import Close from "../../assets/icons/cross";
import { useDispatch, useSelector } from "react-redux";
import SelectCampaignList from "./SelectCampaignList";
import { fetchCampaignBotVoiceList, fetchCampaignLeadList } from "./sagaActions";
import {
  getCampaignCreated,
  getCampaignLeadList,
  getCreateCampaignData,
} from "./selectors";
import {
  setCampaignCreated,
  setCampaignLoadingState,
  setCreateCampaignData,
} from "./reducer";
import { useNavigate } from "react-router-dom";
import { createCampaignFn } from "./createCampaignServices";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import CampaignLegalTerms from "./CampaignLegalTerms";
import CampaignExpect from "./CampaignExpect";
import CampaignInsufficientCredits from "./CampaignInsufficientCredits";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import AddPaymentModal from "../billing/AddPaymentModal";

export const dbColumns: any = [
  "phone1",
  "phone2",
  "phone3",
  "nameFirst",
  "nameLast",
  "addressStreet",
  "addressCity",
  "addressState",
  "addressZip",
];

const getCurrentCampaignStep = (
  step: CampaignSteps,
  onSubmit: Function,
  setStep: Function,
  stepData: CampaignFormModel,
  handleUploadList: Function,
  campaignCost?: any
) => {
  switch (step) {
    case CampaignSteps.SelectList: {
      return (
        <SelectCampaignList
          onSubmit={onSubmit}
          stepData={stepData[CampaignSteps.SelectList]}
          handleUploadList={handleUploadList}
        ></SelectCampaignList>
      );
    }
    case CampaignSteps.StartCampaign: {
      return (
        <StartCampaign
          onSubmit={onSubmit}
          stepData={stepData[CampaignSteps.StartCampaign]}
          setStep={setStep}
          selectedList={stepData.SelectList.leadList}
          selectedListNames={stepData.SelectList.listNames}
          campaignCost={campaignCost}
        ></StartCampaign>
      );
    }
    case CampaignSteps.CampaignCreated: {
      return <CampaignCreated onSubmit={onSubmit}></CampaignCreated>;
    }
    case CampaignSteps.CampaignExpect: {
      return <CampaignExpect onSubmit={onSubmit}></CampaignExpect>;
    }
  }
};

const CreateCampaign = ({
  open,
  setOpen,
  handleUploadList,
}: CreateCampaignProps) => {
  const [step, setStep] = useState<CampaignSteps>(CampaignSteps.SelectList);
  const [stepData, setStepData] = useState<CampaignFormModel>(
    DEFAULT_CAMPAIGN_STEPS_VALUES
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [insufficientCreditsLoader, setInsufficientCreditsLoader] = useState<boolean>(false);
  const campaignCreatedData = useSelector((state) =>
    getCreateCampaignData(state)
  );
  const campaignLeadList = useSelector((state) => getCampaignLeadList(state));
  const [campaignTerms, setCampaignTerms] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [campaignCost, setCampaignCost] = useState(0);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);

  const [payload, setPayload] = useState({
    name: "",
    listIds: [""] as string | string[],
    optimizeEnabled: false,
    dpn: "",
    attemptFrequency: "",
    startDate: "",
    callbackPhone:"",
    voiceId:""
  });

  
  const displayToast = (message: string, type: ToastType) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  
  const onStepSubmit = async (
    data: StartCampaignFormModel | SelectCampaignFormModel
  ) => {
    switch (step) {
      case CampaignSteps.SelectList: {
        const selectedListIds = data?.leadList;
        const selectedLists = campaignLeadList.filter((lead: any) => {
          if (selectedListIds?.includes(lead?.id)) {
            return lead;
          }
        });
        const selectedListNames = selectedLists.map((lead: any) => {
          return lead?.name;
        });
        data = { ...data, listNames: selectedListNames };
        setStepData((prev: CampaignFormModel) => ({
          ...prev,
          [CampaignSteps.SelectList]: data as SelectCampaignFormModel,
        }));
        setStep(CampaignSteps.StartCampaign);
        break;
      }

      case CampaignSteps.StartCampaign: {
        setStepData((prev: CampaignFormModel) => ({
          ...prev,
          [CampaignSteps.StartCampaign]: data as StartCampaignFormModel,
        }));
        setPayload({
          ...payload,
          name: (data as StartCampaignFormModel).campaignName,
          voiceId:(data as StartCampaignFormModel).voiceId,
          callbackPhone:(data as StartCampaignFormModel).callbackPhone,
          startDate: `${(data as StartCampaignFormModel).startDate}`.split(
            "T"
          )[0],
          dpn: (data as StartCampaignFormModel).optimizeCampaign
            ? "3"
            : (data as StartCampaignFormModel).dialsPerNumber,
          optimizeEnabled: (data as StartCampaignFormModel).optimizeCampaign,
          attemptFrequency: (data as StartCampaignFormModel).optimizeCampaign
            ? "3"
            : (data as StartCampaignFormModel).maxAttemptPerNumber,
          listIds: stepData[CampaignSteps.SelectList].leadList as
            | string
            | string[],
        });

        setCampaignTerms(true);
        break;
      }
      case CampaignSteps.CampaignCreated: {
        setStep(CampaignSteps.CampaignExpect);
        break;
      }
      case CampaignSteps.CampaignExpect: {
        const id =
          campaignCreatedData?.data?.result?.campaignListMap[0].campaignId;
        navigate(`/campaigns/${id}`);
        closeModal();
        break;
      }
    }
  };

  const closeModal = () => {
    setOpen(false);
    setStepData(DEFAULT_CAMPAIGN_STEPS_VALUES);
    setStep(CampaignSteps.SelectList);
  };

  // useEffect(() => {
  //   if (campaignCreated) {
  //     // setCampaignTerms(true);
  //     dispatch(setCampaignCreated(false));
  //   }
  // }, [campaignCreated]);

  useEffect(() => {}, [stepData]);
  useEffect(() => {
    dispatch(fetchCampaignLeadList({ successList: true }));
    dispatch(fetchCampaignBotVoiceList({}));
  }, []);
  
  useEffect(() => {
    if (Array.isArray(stepData.SelectList.leadList)) {
      let totalCost = 0;
      const selected = campaignLeadList.filter((lead: any) =>
        stepData.SelectList.leadList.includes(lead?.id)
      );

      selected.forEach((lead: any) => {
        totalCost = totalCost + (lead?.propertyCount ?? 0);
      });
      setCampaignCost(totalCost);
    }
  }, [stepData?.SelectList?.leadList, campaignLeadList]);

  const handleSubmit = async () => {
    try{
      const response = await createCampaignFn(payload);
      dispatch(setCreateCampaignData(response));
      dispatch(setCampaignCreated(true));
      dispatch(setCampaignLoadingState(false));
      setStep(CampaignSteps.CampaignCreated);
    }catch(err:any){
      dispatch(setCampaignLoadingState(false));
      displayToast(err?.response?.data?.message, ToastType.error);
    }
  };

  return (
    <>
      <Dialog
        onClose={closeModal}
        open={open}
        className={`stepsDialog ${
          CampaignSteps.CampaignCreated === step ? `successDialog ` : ``
        }`}
      >
        {CampaignSteps.CampaignCreated === step && (
          <div className="backdropDisable"></div>
        )}
        <div className="dialogWrapper">
          {step != "CampaignCreated" && (
            <span
              role="link"
              onClick={() => closeModal()}
              className="closeDialog"
            >
              <Close />
            </span>
          )}
          {getCurrentCampaignStep(
            step,
            onStepSubmit,
            setStep,
            stepData,
            handleUploadList,
            campaignCost
          )}

          {campaignTerms && (
            <Dialog
              onClose={() => setCampaignTerms(false)}
              open={campaignTerms}
              className="campaignTermsDialog stepsDialog"
            >
              <div className="dialogWrapper">
                <CampaignLegalTerms
                  onSubmit={async () => {
                    dispatch(setCampaignLoadingState(true));
                    try {
                      if (
                        campaignCost >
                        +profileInfo?.organisation?.availableCredit
                      ) {
                        setInsufficientCredits(true);
                      } else {
                        handleSubmit();
                        // setStep(CampaignSteps.CampaignCreated);
                      }
                      setCampaignTerms(false);
                    } catch (error) {
                      dispatch(
                        addToast({
                          id: new Date().getTime(),
                          message: (error as CustomError)?.response?.data
                            ?.message
                            ? (error as CustomError)?.response?.data?.message
                            : (error as CustomError)?.message ??
                              "Something went wrong!",
                          type: ToastType.error,
                        })
                      );
                    } finally {
                      dispatch(setCampaignLoadingState(false));
                    }
                  }}
                  onCancel={() => setCampaignTerms(false)}
                />
              </div>
            </Dialog>
          )}
          {insufficientCredits && (
            <Dialog
              onClose={() => setInsufficientCredits(false)}
              open={insufficientCredits}
              className="campaignTermsDialog stepsDialog"
            >
              <div className="dialogWrapper">
                <span
                  role="link"
                  onClick={() => setInsufficientCredits(false)}
                  className="closeDialog"
                >
                  <Close />
                </span>
                <CampaignInsufficientCredits
                  onSubmit={async () => {
                    try {
                      setInsufficientCreditsLoader(true);
                      const response = await createCampaignFn(payload);
                      dispatch(setCreateCampaignData(response));
                      dispatch(setCampaignCreated(true));
                      dispatch(setCampaignLoadingState(false));
                      setInsufficientCredits(false);
                      setStep(CampaignSteps.CampaignCreated);
                    } catch (error: any) {
                      dispatch(
                        addToast({
                          id: new Date().getTime(),
                          message: (error as CustomError)?.response?.data
                            ?.message
                            ? (error as CustomError)?.response?.data?.message
                            : (error as CustomError)?.message ??
                              "Something went wrong!",
                          type: ToastType.error,
                        })
                      );
                      if (error.response?.data?.errorCode === 'payment_failed') {
                        setOpenAddPaymentModal(true);
                      }
                    } finally {
                      setInsufficientCreditsLoader(false);
                    }
                  }}
                  handleBack={() => {
                    setInsufficientCredits(false);
                    setStep(CampaignSteps.StartCampaign);
                  }}
                  listArray={payload.listIds}
                  insufficientCreditsLoader={insufficientCreditsLoader}
                  openAddPaymentModal={openAddPaymentModal}
                ></CampaignInsufficientCredits>
              </div>
            </Dialog>
          )}
        </div>
      </Dialog>
      {openAddPaymentModal && (
        <AddPaymentModal
          handleClose={() => {
            setOpenAddPaymentModal(false);
          }}
          open={openAddPaymentModal}
          replacePaymentMethod
        ></AddPaymentModal>
      )}
    </>
  );
};
export default CreateCampaign;
