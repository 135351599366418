
import React from "react";
import Button from "../../atoms/Button";
import { formartNumberAsLocalString } from "../../utils/utils";
import styles from "./PurchaseConfirmation.module.scss";
import Shield from "../../assets/icons/shield";
import Check from "../../assets/icons/check";
import Zap from "../../assets/icons/zap";
import Refresh from "../../assets/icons/refresh";
import CheckCircle from "../../assets/icons/checkCircle";
import Trophy from "../../assets/icons/trophy";
import { VA_HOURS_CONSTANTS } from "../../organisms/vaSubscription/constant";
import VaPlanTerm from "../../organisms/vaPlanModal/VaPlanTerm";
import { Dialog } from "@mui/material";

const PurchaseConfirmation = ({
  onSubmit,
  handleClose,
  selectedPricing,
  currentCredits,
  paymentSuccess,
  confirmSubscriptionLoading,
  vaPlanTermModal, setVaPlanTermModal,
  vaPlan
}: any) => {
  const handleSubmit=()=>{
    if(vaPlan){
      setVaPlanTermModal(true)
    }else{
      onSubmit()
    }
  }
  return (
    <>
      {paymentSuccess ? (
        <div className={`${styles.confirm}`}>
          <div className={`${styles.confirm__success}`}>
            <figure>
              <Trophy />
            </figure>
            <div className={`${styles.confirm__header}`}>
              <h2>Congratulations!</h2>
              <p>
                Your transaction has been <u>approved</u> and your plan has been{" "}
                <u>updated</u>, as per your request!
              </p>
            </div>
            <div className={`${styles.confirm__footer}`}>
              <Button
                className="button animated primary full"
                action={handleClose}
                label="Awesome!"
              ></Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles.confirm}`}>
          <div className={`${styles.headerIcon}`}>
            <span className={`${styles.flagBorder}`}>
              {" "}
              <Check />
            </span>
          </div>
          <div className={`${styles.confirm__header}`}>
            <h2>Let's confirm your purchase</h2>
            <p>The following are important details to review.</p>
          </div>
          <div className={`${styles.confirm__info}`}>
            <ul>
              <li>
                <figure>
                  <Zap />
                </figure>
                <figcaption>
                  <p>
                    <strong>
                      $
                      {formartNumberAsLocalString(
                        +(selectedPricing?.unitAmount ?? 0),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                      {vaPlan ? " total due now" : ""}
                    </strong>{" "}
                    {!vaPlan && "total due now"}
                  </p>
                  <p>
                    {" "}
                    Renews <u>automatically</u> every month.
                  </p>
                </figcaption>
              </li>
              <li>
                <figure>
                  <Refresh />
                </figure>
                <figcaption>
                  {vaPlan ? (
                    <p>
                      <strong>Hours are refreshed every month</strong>
                    </p>
                  ) : (
                    <p>
                      <strong>Credits are refreshed</strong> every month
                    </p>
                  )}
                  {vaPlan ? (
                    <p>
                      Your plan includes
                      <strong>
                        {" "}
                        {VA_HOURS_CONSTANTS[currentCredits]} hours of VA Calling
                      </strong>
                      , with Virtual Assistants working in 5-day stretches
                      (Monday–Friday).
                    </p>
                  ) : (
                    <p>
                      Your credits reset to{" "}
                      {formartNumberAsLocalString(currentCredits)} every month.
                    </p>
                  )}
                </figcaption>
              </li>
              {!vaPlan && (
                <li>
                  <figure>
                    <CheckCircle />
                  </figure>
                  <figcaption>
                    <p>
                      <strong>You won't lose your current credits</strong>
                    </p>
                    <p>
                      {" "}
                      {formartNumberAsLocalString(currentCredits)} will be added
                      to your <u>existing</u> credits.
                    </p>
                  </figcaption>
                </li>
              )}
            </ul>
          </div>
          <Button
            action={handleSubmit}
            label={`Pay $${formartNumberAsLocalString(
              +(selectedPricing?.unitAmount ?? 0),
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )} now & confirm subscription`}
            clicked={confirmSubscriptionLoading}
            disabled={confirmSubscriptionLoading}
            className="button primary animated full"
            postfix={<Shield />}
          ></Button>
        </div>
      )}

      {vaPlanTermModal && (
        <Dialog
          onClose={() => setVaPlanTermModal(false)}
          open={vaPlanTermModal}
          className="campaignTermsDialog stepsDialog"
        >
          <div className="dialogWrapper">
            <VaPlanTerm
              onSubmit={onSubmit}
              confirmSubscriptionLoading={confirmSubscriptionLoading}
              onCancel={() => setVaPlanTermModal(false)}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default PurchaseConfirmation;
