export enum UserCompletedStep {
  STEP_ZERO = 0,
  STEP_ONE = 1,
  STEP_TWO = 2,
}

const id =
  window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 1
  ];

export const pageNameNumber: Record<string, number> = {
  "campaigns": 0,
  "leads": 1,
  "lists": 2,
  "campaigndetail": 3,
  "properties" : 4,
  "property-search" : 5,
  "welcome": 6
};

export const dynamicPathObj = (currentPage: string|number) => {
  switch (currentPage) {
    case 0:
      return {
        title: "Welcome to Campaign Manager!",
        subtitle: "See an overview of all your campaigns.",
        video: "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorials_Campaign+Manager.mov",
        path: "/campaigns",
        pagename: "campaigns",
      };
    case 1:
      return {
        title: "Welcome to Leads!",
        subtitle: "Manage and interact with your leads.",
        video: "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorial_Lead+Manager.mov",
        path: "/leads",
        pagename: "leads",
      };
    case 2:
      return {
        title: "Welcome to Lists!",
        subtitle: "View your purchased lists or upload your own.",
        video: "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorials_Lists.mov",
        path: "/lists",
        pagename: "lists",
      };
    case 3:
      return {
        title: "Welcome to Campaign Details!",
        subtitle: "See detailed information on a campaign",
        video:"https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorial_Campaign+Details.mov",
        path: `/campaigns/${id}`,
        pagename: "campaignDetail",
      };

    case 4:
      return {
        title: "Welcome to Property Details!",
        subtitle: "View detailed information on a specific property.",
        video: "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorials_Property+Details.mov",
        path: `/properties/${id}`,
        pagename: "propertyDetail",
      };

    case 5:
      return {
        title: "Welcome to Property Search!",
        subtitle: "Search and filter properties to curate robust lists.",
        video: "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorial_Property+Search.mov",
        path: "/property-search",
        pagename: "propertySearch",
      };

      case 6:
        return {
          title: "Welcome to OttoLeads!",
          subtitle: "Here's a special message from our founders.",
          video: "https://d8eetllu6sdl7.cloudfront.net/OttoLeads_Tutorials_Campaign+Manager.mov",
          path: "/property-search",
          pagename: "propertySearch",
        };
  }
};

export const pageNameFn=(path : any,id?: string)=>{
  let pageId;
  if(id){
   pageId  = pageNameNumber?.campaigndetail;
  }
  else{
    pageId = pageNameNumber?.[path];
  }

  return dynamicPathObj(pageId);
}

export enum LOGIN_TYPE {
  GOOGLE = "GOOGLE",
  DEFAULT = "DEFAULT",
}

export enum PermissionListEnum {
  ORGANISATION_OWNER = "Owner",
  ORGANISATION_ADMIN = "Admin",
  ORGANISATION_VIEWER = "Viewer",
  ORGANISATION_VA = "VA",
}