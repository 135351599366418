import { Dialog, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "socket.io-client";
import Chat from "../../assets/icons/chat";
import Copy from "../../assets/icons/copy";
import FileCheck from "../../assets/icons/fileCheck";
import SearchReflation from "../../assets/icons/searchReflation";
import Target from "../../assets/icons/target";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import CustomModal from "../../components/customModal/CustomModal";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import {
  LeadStatus,
  LeadStatusMap,
} from "../../constants/leadstatus.constants";
import { useDbounce } from "../../hooks/debounce.hook";
import CustomTable from "../../molecules/table/CustomTable";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { LeadFilters } from "../../pages/leads/interface";
import PropertyDetails, {
  LEAD_STATUS_ICONS,
} from "../../pages/property/PropertyDetails";
import LeadActivityDrawer from "../../pages/property/leadDetailsModal/LeadDetailsModal";
import { PropertyDetailsParentIdType } from "../../pages/property/property.enum";
import { resetPropertyDetailsState } from "../../pages/property/property.reducer";
import { propertyUpdateDetailsAction } from "../../pages/property/property.sagaAction";
import { updatePropertyDetails } from "../../pages/property/property.service";
import {
  copyToClipboard,
  followUpDateFormatter,
  formatPhoneNumber,
} from "../../utils/utils";
import { toggleLeadsModal } from "../addLeads/reducer";
import { PermissionListEnum } from "../auth/enum";
import {
  ownerNameUpdate,
  resetOwnerName,
} from "../propertySearchFilter/PropertyService";
import { columns } from "./constants";
import { LeadsTableProps } from "./interface";
import {
  handleLoaderForRow,
  resetLeadState,
  setLeadList,
  setLeadListIsLoading,
  setSelectedLeadId,
} from "./reducer";
import { fetchLeadList } from "./sagaActions";
import {
  getLeadListIsLoading,
  getLeadListTotal,
  getLeadsList,
  getSelectedLeadId,
} from "./selectors";

const LeadTable = ({
  filter,
  handleNextPage,
  handleClearSearch,
  handleSorting,
  showActivityDrawer,
  setShowActivityDrawer,
}: LeadsTableProps) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [activityDrawerLeadId, setActivityDrawerLeadId] = useState(0);
  const leadList = useSelector((state) => getLeadsList(state));
  const total = useSelector((state) => getLeadListTotal(state));
  const leadListIsLoading = useSelector((state) => getLeadListIsLoading(state));
  const profileData = useSelector((state) => getProfileInfo(state));
  const [updateLead, setUpdateLead] = useState<Record<string, any>[]>([]);
  const [editNameModal, setEditNameModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [loading, setLoading] = useState(false);
  const socketRef = useRef<any>();
  const nameRef = useRef("");
  const selectedId = useRef(0);
  const selectedLeadId = useSelector((state) => getSelectedLeadId(state));
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const LeadStatusOptions = Object.entries(LeadStatusMap).map(
    ([key, value]) => ({
      label: key,
      value: value,
      prefix: LEAD_STATUS_ICONS[value],
    })
  );

  let searchState: string = "";
  if (
    filter?.searchText ||
    filter?.leadStatus?.length ||
    filter?.campaignIds?.length ||
    filter?.isTodayFollowUp
  ) {
    searchState = "true";
  }

  const debounce = useDbounce(500, (data: LeadFilters) => {
    dispatch(
      fetchLeadList({
        search: data.searchText,
        index: data.pageNumber,
        size: data.limit,
        isTodayFollowUp: data.isTodayFollowUp,
        campaignIds: data.campaignIds,
        status: data.leadStatus,
        sortDirection: data.sortDirection,
        sortKey: data.sortKey,
      })
    );
  });

  const handlePropertyModal = () => {
    setShowPropertyModal(false);
    dispatch(setSelectedLeadId(""));
  };
  const handleStatusChange = async (lead: any) => {
    try {
      await updatePropertyDetails({
        id: lead?.id,
        payload: { status: LeadStatus[lead?.value] },
        type: PropertyDetailsParentIdType.LEAD_ID,
      });
      dispatch(
        handleLoaderForRow({
          data: { id: lead?.id, key: "status", value: LeadStatus[lead?.value] },
        })
      );
    } catch (error) {}
  };

  const handleDateChange = (lead: any) => {
    dispatch(
      propertyUpdateDetailsAction({
        id: lead?.id,
        followUpAt: lead?.followUpAt ? lead?.followUpAt.split("T")[0] : null,
        type: "leadId",
      })
    );
    let list=[...leadList];
    let newTableData: any = list.map((el: any) => {
      if (el.id === lead?.id) {
        return { ...el, followUpAt: lead?.followUpAt ? lead?.followUpAt.split("T")[0] : null };
      }
      return el;
    });
    dispatch(handleLoaderForRow({ data: { id:lead?.id, key:"followUpAt", value: lead?.followUpAt ? lead?.followUpAt.split("T")[0] : null }}))
  };

  const generateCopy = (lead: any, type: string) => {
    const addressData = `${[
      lead.addressStreet,
      lead.addressCity,
      lead.addressState,
      lead.addressZip,
    ]}`;
    const phoneData = `${[
      lead?.phone?.number && formatPhoneNumber(lead?.phone?.number),
    ]}`;
    const summaryData = `${[lead?.latestPhoneAttempt?.summary]}`;
    const leadName = `${[
      lead?.owner[0]?.nameFirst + " " + lead?.owner[0]?.nameLast,
    ]}`;
    let text;
    if (type === "leadName") {
      text = leadName;
    } else if (type === "address") {
      text = addressData;
    } else if (type === "phone") {
      text = phoneData;
    } else {
      text = summaryData;
    }

    const copyTextData = `${[text]
      .filter((item) => typeof item === "string")
      .map((item) => item.trim())
      .filter(Boolean)
      .join(", ")}`;

    return (
      <Tooltip
        title={
          type === "leadName"
            ? "Copy Lead Name"
            : type === "address"
            ? "Copy Lead Address"
            : type === "phone"
            ? "Copy Phone Number"
            : "Copy Summary"
        }
      >
        <span
          role="link"
          className="copy-icon"
          onClick={(e: any) => {
            e.stopPropagation();
            copyToClipboard(copyTextData);
          }}
        >
          <Copy />
        </span>
      </Tooltip>
    );
  };

  const handleRowOnClick = (id: any) => {
    // window.open(`/properties/${id}`);
    // window.scrollTo(0, 0);
    dispatch(setSelectedLeadId(id));
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleEditName = (value: string, id: number) => {
    selectedId.current = id;
    nameRef.current = value;
    setSelectedName(value);
    setEditNameModal(!editNameModal);
  };

  const renameLead = async () => {
    if (nameRef.current === selectedName) {
      selectedId.current = 0;
      nameRef.current = "";
      setSelectedName("");
      setEditNameModal(!editNameModal);
    } else {
      try {
        setLoading(true);
        await ownerNameUpdate(
          selectedId.current,
          {
            fullName: nameRef.current,
          },
          "leadId"
        );
        const data = updateLead.map((item) =>
          item.id === selectedId.current
            ? {
                ...item,
                owner: item.owner.map((ownerItem: any, index: any) =>
                  index === 0
                    ? {
                        ...ownerItem,
                        nameFirst: nameRef.current.split(" ")[0] || "",
                        nameLast:
                          nameRef.current.split(" ").slice(1).join(" ") || "",
                      }
                    : ownerItem
                ),
              }
            : item
        );

        dispatch(
          setLeadList({ ...leadList, data: { result: data, count: total } })
        );
        handleShowToast(
          `${nameRef.current} lead name is updated successfully! `,
          ToastType.success
        );
        setLoading(false);
        setEditNameModal(false);
        selectedId.current = 0;
        nameRef.current = "";
      } catch (error: any) {
        handleShowToast("Lead name is required", ToastType.error);
        setUpdateLead([...leadList]);
        setLoading(false);
      }
    }
  };

  const handleResetName = async () => {
    try {
      setResetLoading(true);
      const response = await resetOwnerName(selectedId.current, "leadId");
      const data = updateLead.map((item) =>
        item.id === selectedId.current
          ? {
              ...item,
              owner: item.owner.map((ownerItem: any, index: any) =>
                index === 0
                  ? {
                      ...ownerItem,
                      nameFirst: response?.data?.firstName || "",
                      nameLast: response.data.lastName || "",
                    }
                  : ownerItem
              ),
            }
          : item
      );

      dispatch(
        setLeadList({ ...leadList, data: { result: data, count: total } })
      );
      handleShowToast("Owner name reset successfully!", ToastType.success);
      // handleEditName();
      setResetLoading(false);
      setSelectedName("");
      setEditNameModal(!editNameModal);
    } catch (error: any) {
      console.log(error);
      handleShowToast(error?.response?.data?.message, ToastType.error);
      setResetLoading(false);
      setEditNameModal(!editNameModal);
    }
  };

  useEffect(() => {
    setUpdateLead([...leadList]);
  }, [leadList]);

  useEffect(() => {
    if (selectedLeadId) {
      setShowPropertyModal(true);
    }
  }, [selectedLeadId]);

  useEffect(() => {
    const leadData = leadList?.map((lead: any) => {
      const selectedOwner =
        lead?.owner?.find((owner: any) => owner.createdBy) || lead?.owner?.[0];
      const title = `${selectedOwner?.nameFirst || ""} ${
        selectedOwner?.nameLast || ""
      }`;
      setValue(
        `follow_up_at_${lead?.id}`,
        lead?.followUpAt ? lead?.followUpAt.split("T")[0] : null
      );
      return {
        id: lead.id,
        role: 
          profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER 
            ? true
            : false,
        phone: lead?.phone?.number
          ? formatPhoneNumber(lead?.phone?.number)
          : "-",
        phone_attempt_summary: lead?.latestPhoneAttempt?.summary || "-",
        lead: {
          title: title,
          description: [
            lead.addressStreet,
            lead.addressCity,
            lead.addressState,
            lead.addressZip,
          ]
            .filter((item) => {
              if (typeof item === "string") {
                item = item.trim();
              }

              return item;
            })
            .filter(Boolean)
            .join(", "),
          copyAddress: generateCopy(lead, "address"),
          notEdittable: true,
          copyLeadName: generateCopy(lead, "leadName"),
          tooltipRequired: true,
        },
        // bed: parseFloat(lead?.bed) > 0 ? lead?.bed : "—",
        // bath: parseFloat(lead?.bath) > 0 ? lead?.bath : "—",
        // sqft:
        //   typeof lead?.sqft === "number" && !isNaN(lead?.sqft)
        //     ? formartNumberAsLocalString(lead?.sqft)
        //     : "—",
        status: lead?.status,
        followUpDate: followUpDateFormatter(
          lead.followUpAt ? new Date(lead?.followUpAt) : null
        ),
        handleOnClicked: () => handleRowOnClick(lead.id),
        activityDrawerLeadId: activityDrawerLeadId,
        commentButton: (
          <span
            className="chat-icon"
            onClick={(e: any) => {
              e.stopPropagation();
              if (activityDrawerLeadId === Number(lead.id)) {
                setShowActivityDrawer(false);
                setActivityDrawerLeadId(0);
              } else {
                setShowActivityDrawer(true);
                setActivityDrawerLeadId(Number(lead.id));
              }
            }}
          >
            <Chat />
            {lead?.totalCount != 0 ? <span>{lead?.totalCount}</span> : ""}
          </span>
        ),
        updatedAtDate: followUpDateFormatter(
          lead.updatedAt ? new Date(lead?.updatedAt) : null
        ),
        isLoading: lead?.isLoading,
        copyPhone: generateCopy(lead, "phone"),
        copySummary: generateCopy(lead, "summary"),
        rootUser: false,
      };
    });
    setTableData(leadData ?? []);
  }, [leadList, activityDrawerLeadId, profileData]);

  useEffect(() => {
    leadList.forEach((lead: any) => {
      const statusValue = Object.keys(LeadStatus).find(
        (el) => LeadStatus[el] === lead?.status
      );
      setValue(`status${lead?.id}`, statusValue);
    });
  }, [leadList]);

  useEffect(() => {
    return () => {
      dispatch(resetPropertyDetailsState({}));
    };
  }, []);

  useEffect(() => {
    if (
      filter.searchText.length > 0 &&
      filter.searchText.length !== filter.searchText.trim().length
    ) {
      return;
    }
    if (filter.pageNumber === 1) {
      dispatch(setLeadListIsLoading(true));
    }
    if (!showPropertyModal) {
      debounce(filter);
    }
  }, [filter, showPropertyModal]);

  useEffect(() => {
    return () => {
      dispatch(resetLeadState({}));
      dispatch(setSelectedLeadId(""));
    };
  }, []);

  useEffect(() => {
    socketRef.current = (connect as any)(
      `${process.env.REACT_APP_WS_BASE_URL}/notifications`,
      {
        extraHeaders: {
          "x-user-id": profileData?.id,
        },
      }
    );
    socketRef.current.on("lead-comment", (data: any) => {
      let newTableData: any = leadList.map((el: any) => {
        if (el?.id == data?.propertyId) {
          return { ...el, commentCount: data?.commentCount };
        }

        return el;
      });
      dispatch(
        setLeadList({
          ...leadList,
          data: { result: newTableData, count: total },
        })
      );
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [leadList]);

  return (
    <>
      {leadListIsLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <>
          <CustomTable
            rows={tableData}
            control={control}
            errors={errors}
            columns={columns}
            total={total}
            tableStriped
            currentPageIndex={1}
            search={searchState}
            sortKey={filter.sortKey}
            sortDirection={filter.sortDirection}
            options={LeadStatusOptions}
            handleClickedPill={handleStatusChange}
            handleDateSelected={handleDateChange}
            hasMore={tableData.length < total}
            // handleSaveKeyPress={handleSaveKeyPress}
            handleEditName={handleEditName}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search or filtering criteria.",
              button1:
                profileData?.userRoleName ===
                PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
                PermissionListEnum.ORGANISATION_VA ? (
                  <Button
                    label="Create Lead"
                    className="primary animated"
                    prefix={<FileCheck />}
                    disabled={
                      profileData?.userRoleName ===
                      PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
                      PermissionListEnum.ORGANISATION_VA
                    }
                  />
                ) : (
                  <Button
                    label="Create Lead"
                    className="primary animated"
                    prefix={<FileCheck />}
                    action={() => dispatch(toggleLeadsModal(true))}
                    dataTestId="createLeadNoResId"
                  />
                ),
              button2: (
                <Button
                  label="Clear search"
                  className="outline"
                  action={handleClearSearch}
                  dataTestId="clearSearchTestId"
                />
              ),
            }}
            emptyTableMetaData={{
              icon: <Target />,
              title: "Create New Lead",
              description: "Click the button below to get started.",
              button1:
                profileData?.userRoleName ===
                PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
                PermissionListEnum.ORGANISATION_VA ? (
                  <Tooltip title={ROLE_PERMISSION_STATUS.NoAccess}>
                    <div>
                      <Button
                        label="Create Lead"
                        className="primary animated"
                        prefix={<FileCheck />}
                        disabled={
                          profileData?.userRoleName ===
                          PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
                          PermissionListEnum.ORGANISATION_VA
                        }
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    label="Create Lead"
                    className="primary animated"
                    prefix={<FileCheck />}
                    action={() => dispatch(toggleLeadsModal(true))}
                  />
                ),
            }}
            handleNextPage={handleNextPage}
            handleSorting={handleSorting}
            handleDateFn={handleDateChange}
            dataTestId={"leadsDataTestId"}
          ></CustomTable>
          <LeadActivityDrawer
            key={activityDrawerLeadId}
            propertyId={activityDrawerLeadId}
            showCommentBox={showActivityDrawer}
            setShowpopup={(value) => {
              setActivityDrawerLeadId(0);
              setShowActivityDrawer(value);
            }}
            type="leadId"
          />
          <Dialog
            className="stepsDialog editModal"
            onClose={() => setEditNameModal(!editNameModal)}
            open={editNameModal}
          >
            <div className={`editModal`}>
              <label>Owner name*</label>
              <input
                placeholder="Owner name"
                type="text"
                defaultValue={nameRef.current}
                onChange={(e: any) => (nameRef.current = e.target.value.trim())}
                disabled={loading || resetLoading}
              />

              <div className={`editModal__button dflex justifyCenter`}>
                <div className={`editModal__col`}>
                  <Button
                    className="primary full animated"
                    label="Save"
                    action={renameLead}
                    clicked={loading}
                    disabled={loading || resetLoading}
                  />
                </div>
                <div className={`editModal__col`}>
                  <Button
                    className="outline full "
                    label="Reset"
                    action={handleResetName}
                    clicked={resetLoading}
                    disabled={resetLoading || loading}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </>
      )}
      {selectedLeadId && (
        <CustomModal open={showPropertyModal} handleClose={handlePropertyModal}>
          <PropertyDetails
            displayText={"Leads"}
            selectedPropertyId={selectedLeadId}
            handleModalClose={handlePropertyModal}
            type={PropertyDetailsParentIdType.LEAD_ID}
          />
        </CustomModal>
      )}
    </>
  );
};
export default LeadTable;
