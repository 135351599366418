import React, { useEffect, useState } from "react";
import CallAttemptsNote from "../propertyTabs/callAttemptsNote/CallAttemptsNote";
import DropDown from "../../../atoms/DropDown";
import { Tooltip } from "@mui/material";
import { copyToClipboard, formatPhoneNumber } from "../../../utils/utils";
import Copy from "../../../assets/icons/copy";
import PhoneV2 from "../../../assets/icons/phoneV2";
import styles from "./AddedCallAttempts.module.scss";
import Button from "../../../atoms/Button";
import Plus from "../../../assets/icons/plus";
import Edit from "../../../assets/icons/edit";
import { editCallAttempt } from "../property.service";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyDetails } from "../property.selector";
import { fetchCallAttempts } from "../property.sagaAction";
import { ToastType } from "../../../molecules/toaster/enum";
import { addToast } from "../../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../../../organisms/auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../../constants/constants";
import { getProfileInfo } from "../../../components/profileMenu/selectors";

const AddedCallAttempts = ({
  callAttemptsStatusOptions,
  control,
  errors,
  attemptsDetail,
  setValue,
  getValues,
}: any) => {
  const dispatch = useDispatch();
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});
  const [logButtonLoading, setLogButtonLoading] = useState(-1);
  const [noteContent, setNoteContent] = useState<string>('');
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const handleAddNote = async (htmlContent: string) => {
    setNoteContent(htmlContent);
  };

  const handleEditClick = (attempt: string) => {
    if (Object.values(editMode).includes(true) && !editMode[attempt]) {
      return;
    }
    setEditMode((prev: any) => ({
      ...prev,
      [attempt]: !prev[attempt],
    }));
  };
  const groupedData = attemptsDetail.reduce((acc: any, curr: any) => {
    if (!acc[curr.attempt]) {
      acc[curr.attempt] = [];
    }
    acc[curr.attempt].push(...curr.callAttempts);
    return acc;
  }, {});

  const sortedData = [...attemptsDetail].sort(
    (a: any, b: any) => b.attempt - a.attempt
  );

  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleEditLogAttempts = async (item: any) => {
    const attemptData = groupedData[item.attempt];
    const phones = attemptData.map((call: any) => {
      const status = getValues(`status_${call.id}`) || call.status;
      return {
        phoneNumber: call.phoneNumber,
        status: status,  
      };
    });
    try {
      const payload = { logId: item.id, phones: phones,summary:noteContent };
      setLogButtonLoading(item.id);
      await editCallAttempt(propertyDetails.leadId, payload);
      setLogButtonLoading(-1);
      dispatch(fetchCallAttempts(propertyDetails.leadId));
    } catch (error:any) {
      console.log(error);
     handleShowToast(error.response.data.message, ToastType.error);
      setLogButtonLoading(-1);
    }
  };

  useEffect(() => {
    Object.keys(groupedData).forEach((attempt) => {
      groupedData[attempt].forEach((item: any) => {
        setValue(`status_${item.id}`, item.status);
      });
    });
  }, [groupedData, setValue]);

  return (
    <div className={` ${styles.attempt}`}>
      {sortedData.map((item: any) => (
        <div className={` ${styles.attempt__list}`} key={item.id}>
          <div
            className={`dflex justifySpaceBetween alignCenter ${styles.attempt__header}`}
          >
            <h3>Call Attempt #{item.attempt}</h3>
            <div className={`flex ${styles.attempt__btn}`}>
              {item?.createdBy ===profileInfo?.id &&<Button
                className="outline"
                prefix={<Edit />}
                action={() => handleEditClick(item.attempt.toString())}
                disabled={Object.values(editMode).includes(true)}
              />}
              <Tooltip
                              title={
                                profileInfo?.userRoleName ===
                                PermissionListEnum.ORGANISATION_VIEWER
                                  ? ROLE_PERMISSION_STATUS.NoAccess
                                  : ""
                              }
                            >
                              <div>

              <Button
                className="borderGradient"
                label="Save"
                prefix={<Plus />}
                disabled={!editMode[item.attempt] || item.id === logButtonLoading ||  profileInfo?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER}
                clicked={item.id === logButtonLoading}
                action={() => handleEditLogAttempts(item)}
              />
              </div>
              </Tooltip>
            </div>
          </div>
          <div className={`dflex ${styles.attempt__main}`}>
            <div className={` ${styles.attempt__info}`}>
              {item.callAttempts.map((call: any, index: any) => (
                <div
                  className={` ${styles.attempt__phone} flex alignCenter`}
                  key={call.id}
                >
                  <figure className="flex alignCenter">
                    <PhoneV2 /> <label>Phone {index + 1}</label>
                  </figure>
                  <figcaption>
                    <div
                      className={`${styles.attempt__phoneMain} flex alignCenter`}
                    >
                      <a href={`tel:${call.phoneNumber}`}>
                        {formatPhoneNumber(call.phoneNumber)}
                      </a>
                      <Tooltip title={"Copy Phone Number"}>
                        <span
                          role="link"
                          className={` ${styles.attempt__copy} copy-icon`}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            copyToClipboard(call.phoneNumber);
                          }}
                        >
                          <Copy />
                        </span>
                      </Tooltip>
                    </div>
                  </figcaption>
                  <div className={` ${styles.attempt__status} ${!editMode[item.attempt] ? styles.pillMode : styles.normalMode } ${call?.status === "Hidden" && styles.disabled}`}>
                    {call?.status === "Hidden"  && !editMode[item.attempt] ? <span className={`${styles.selectStatus}`}>Select Status</span> :
                    <DropDown
                      options={callAttemptsStatusOptions}
                      clearOnBlur={true}
                      label=""
                      control={control}
                      name={`status_${call.id}`}
                      errors={errors}
                      placeholder="Select Status"
                      closeOnOutsideClick={true}
                      handleOnChange={(data: any) => {
                        console.log(data.value);
                      }}
                      disabled={!editMode[item.attempt]}
                      defaultValue={call?.status}
                      small={!editMode[item.attempt]}
                      smallHeight={!editMode[item.attempt]}
                      handleOnFocus={(e: any) => handleOnFocus(e)}
                    />}
                  </div>
                </div>
              ))}
            </div>
            <div className={` ${styles.attempt__note}`}>
              <CallAttemptsNote
                onSend={handleAddNote}
                detail={item}
                newAttempt={false}
                edit={editMode[item.attempt] ? editMode[item.attempt] : false}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddedCallAttempts;
