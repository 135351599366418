import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import Announcement from "../../assets/icons/announcement";
import ArrowLeft from "../../assets/icons/arrowLeft";
import CalendarCheck from "../../assets/icons/calendarCheck";
import ClockForward from "../../assets/icons/clockForward";
import CloseDeal from "../../assets/icons/closeDeal";
import HelpCircle from "../../assets/icons/helpCircle";
import Hot from "../../assets/icons/hot";
import NewLead from "../../assets/icons/newLead";
import SendIcon from "../../assets/icons/sendIcon";
import SnowFlakes from "../../assets/icons/snowflakes";
import Sun from "../../assets/icons/sun";
import UnderContract from "../../assets/icons/underContract";
import Button from "../../atoms/Button";
import DropDown from "../../atoms/DropDown";
import { updateProfileAction } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import {
  LeadStatus,
  LeadStatusMap,
} from "../../constants/leadstatus.constants";
import { useHelpModalContext } from "../../context/HelpModalContext";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import AddContactModal from "../../organisms/addContactModal/AddContactModal";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { PermissionListEnum, pageNameFn } from "../../organisms/auth/enum";
import { getSelectedLeadId } from "../../organisms/leadsTable/selectors";
import SingleSkipTraceModal from "../../organisms/singleSkipTraceModal/SingleSkipTraceModal";
import WelcomeModal from "../../organisms/welcomeModal/WelcomeModal";
import { ModalCloseReason } from "../../organisms/welcomeModal/interface";
import {
  fbc,
  fbp,
  fetchIp,
  formartNumberAsLocalString,
  formartSqftToAcres,
  hashNumber,
} from "../../utils/utils";
import ImageMapView from "./ImageMapView";
import styles from "./PropertyDetails.module.scss";
import { PropertyDetailsDisplay, PropertyDetailsParentIdType } from "./property.enum";
import { resetPropertyDetailsState } from "./property.reducer";
import { fetchPropertyDetailsAction } from "./property.sagaAction";
import { getPropertyDetails, isLoading } from "./property.selector";
import { fetchNoteCount, updatePropertyDetails } from "./property.service";
import PropertyTabs from "./propertyTabs/PropertyTab";
import { getLeadsListDetail } from "../../organisms/listsTable/selectors";
import { setLeadListDetail } from "../../organisms/listsTable/reducer";
export const LEAD_STATUS_ICONS = {
  NEW_LEAD: <NewLead color="#17B26A" />,
  FOLLOW_UP: <ClockForward color="#875BF7" />,
  CONTRACT_SENT: <SendIcon color="#15B79E" />,
  CLOSED_DEAL: <CloseDeal />,
  PROSPECTING: <Announcement color="#7499F9" />,
  HOT: <Hot color="#F04438" />,
  WARM: <Sun color="#F79009" />,
  COLD: <SnowFlakes color="#00488D" />,
  APPOINTMENT_SET: <CalendarCheck color="#D444F1" />,
  UNDER_CONTRACT: <UnderContract />,
};

const LeadStatusOptions = Object.entries(LeadStatusMap).map(([key, value]) => ({
  label: key,
  value: value,
  prefix: LEAD_STATUS_ICONS[value],
}));

const PropertyDetails = ({
  selectedPropertyId,
  type,
  handleModalClose,
  displayText,
}: any) => {
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const { status = "", followUpAt = "" } = propertyDetails;
  const isPropertyDetailsLoading = useSelector((state) => isLoading(state));
  const dispatch = useDispatch();

  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showSingleSkipTraceModal, setshowSingleSkipTraceModal] =
    useState(false);
  const selectedLeadId = useSelector((state) => getSelectedLeadId(state));
  const {
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      follow_up_at: followUpAt ?? "",
      status: status ?? "",
    },
  });
  const [selectedMarkers, setSelectedMarkers] = useState<any[]>([]);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [showModal, setShowModal] = useState(false);
  const [showNoteCount, setShowNoteCount] = useState(0);
  const { openWelcomeModal, modalContent, setModalContent } =
    useHelpModalContext();
  const location = useLocation();
  const page: any = pageNameFn(location?.pathname.split("/")[1]);
  const listDetailRes = useSelector((state) => getLeadsListDetail(state));
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const handleClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: ModalCloseReason
  ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      dispatch(
        updateProfileAction({
          [page?.pagename]: true,
          organisationName: profileInfo?.organisation?.organisationName,
        })
      );
    }
    setShowModal(false);
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      setShowModal(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetPropertyDetailsState({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (followUpAt || status) {
      const statusValue = Object.keys(LeadStatus).find(
        (el) => LeadStatus[el] === status
      );

      reset({
        follow_up_at: followUpAt,
        status: statusValue,
      });
    }
  }, [followUpAt, status]);

  const handleClickSkipTrace = () => {
    setshowSingleSkipTraceModal(true);
  };
  const handleClickAddContact = () => {
    setShowAddContactModal(!showSingleSkipTraceModal);
  };

  useEffect(() => {
    if (!selectedPropertyId) return;
    dispatch(
      fetchPropertyDetailsAction({ id: selectedPropertyId, type: type })
    );
  }, [dispatch, selectedPropertyId]);

  useEffect(() => {
    if (propertyDetails?.jsonData?.id) {
      setSelectedMarkers([propertyDetails?.jsonData?.id]);
    }
    if (propertyDetails?.leadId && displayText !== PropertyDetailsDisplay.PROPERTY_SEARCH) {
      const notesCount = async () => {
        const res = await fetchNoteCount(propertyDetails?.leadId);
        setShowNoteCount(res.data.commentCount);
      };
      notesCount();
    }
  }, [propertyDetails]);

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Property_Detail_View",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(
                  profileInfo?.data?.result?.organisationId
                ),
              },
            },
          ],
        };

        await FbConversionApi(payloadData);
      } catch (error) {
        console.error("Error sending payload:", error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  // if (isPropertyDetailsLoading) {
  //   return (
  //     <div className="flex loader-full alignCenter justifyCenter">
  //       <div className="dot-pulse dot-white"></div>
  //     </div>
  //   );
  // }
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  const { propertyInformation, lot, longitude, latitude } = propertyDetails;

  let addressStr = propertyDetails?.mailingAddress;
  const label = addressStr ? addressStr : "-";

  const features: {
    [key: string]: any;
  } = {
    bed: propertyInformation?.bedroomCount
      ? `<strong>
      ${propertyInformation?.bedroomCount}
      </strong>`
      : "-",
    bath: propertyInformation?.bathroomCount
      ? `<strong>
        ${propertyInformation?.bathroomCount}
      </strong>`
      : "-",
    sqft: propertyInformation?.livingAreaSquareFeet
      ? ` <strong>
        ${formartNumberAsLocalString(propertyInformation?.livingAreaSquareFeet)}
      </strong>`
      : "-",
    "acres": lot?.lotSizeAcres
      ? `<strong>
      ${formartNumberAsLocalString(lot?.lotSizeAcres)}
      </strong>`
      : "-",
    "year built": propertyInformation?.yearBuilt
      ? `<strong>
      ${propertyInformation?.yearBuilt}
      </strong>`
      : "-",
  };

  const handleStatusChange = async (statusValue: any) => {
    try {
      await updatePropertyDetails({
        id:
          type === PropertyDetailsParentIdType.LEAD_ID
            ? selectedLeadId ||propertyDetails?.leadId
            : propertyDetails?.propertyId,
        payload: { status: LeadStatus[statusValue?.value] },
        type,
      });
      const updatedListDetailRes = listDetailRes?.map((id: any) => {
        if (id.leadId === propertyDetails?.leadId) {
          return { ...id, leadStatus: LeadStatus[statusValue?.value] };
        }
        return id;
      });
      dispatch(setLeadListDetail(updatedListDetailRes));

      // handleShowToast("Status updated successfully!");
    } catch (error: any) {
      handleShowToast(
        error?.message || "Something went wrong!",
        ToastType.error
      );
    }
  };

  const propertyfeaturesAsString = Object.keys(features)
    .map((key) => (features[key] ? `${features[key]} ${key}` : ""))
    .filter(Boolean)
    .join(" | ");

  const AboveMap = () => {
    return (
      <div className={` ${styles.property__top}`}>
        <div className="bottom-zero">
          <div
            className={` ${styles.propertyHeader} dflex justifySpaceBetween alignCenter`}
          >
            <div className={` ${styles.propertyHeader__left} `}>
              <span
                role="link"
                className={` ${styles.propertyHeader__leftBack} flex alignCenter`}
                onClick={() => handleModalClose()}
              >
                <ArrowLeft />
                Back to {displayText}
              </span>
              <h2>
                {label}
                <Tooltip onClick={() => openWelcomeModal(page)} title={""}>
                  <span role="link">
                    <HelpCircle />
                  </span>
                </Tooltip>
              </h2>
              <p
                dangerouslySetInnerHTML={{ __html: propertyfeaturesAsString }}
              ></p>
            </div>
            {showModal && (
              <WelcomeModal
                title={modalContent.title}
                subtitle={modalContent.subtitle}
                button={
                  <Button
                    action={handleClose}
                    className="primary xl full"
                    label="Got it"
                  />
                }
                video={modalContent.video}
                open={showModal}
                handleClose={handleClose}
              />
            )}
            <div
              className={`dflex alignCenter ${styles.propertyHeader__right}`}
            >
              <Tooltip
                title={
                  profileInfo?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER
                    ? ROLE_PERMISSION_STATUS.NoAccess
                    : ""
                }
              >
                <div className={`${styles.propertyHeader__rightDropdown}`}>
                  <DropDown
                    options={LeadStatusOptions}
                    clearOnBlur={true}
                    label=""
                    control={control}
                    name="status"
                    errors={errors}
                    placeholder="Status"
                    handleOnChange={handleStatusChange}
                    gradientBorder
                    updatedUi
                    smallHeight
                    disabled={
                      profileInfo?.userRoleName ===
                      PermissionListEnum.ORGANISATION_VIEWER
                    }
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleMarker = (id: number | string) => {
    if (selectedMarkers.includes(id)) {
      setSelectedMarkers((prev) => prev.filter((marker) => marker !== id));
    } else {
      setSelectedMarkers((prev) => [...prev, id]);
    }
  };

  return (
    <>
      {/* {createPortal(
        <LeadActivityDrawer
          propertyId={selectedPropertyId || ""}
          showCommentBox={showCommentBox}
          setShowpopup={setShowCommentBox}
          type={type}
        />,
        document.body
      )} */}

      <SingleSkipTraceModal
        propertyId={propertyDetails.propertyId || ""}
        show={showSingleSkipTraceModal}
        setShow={setshowSingleSkipTraceModal}
      />
      <AddContactModal
        show={showAddContactModal}
        setShow={setShowAddContactModal}
      ></AddContactModal>

     {isPropertyDetailsLoading ? 
     <div className="flex loader-full alignCenter justifyCenter">
     <div className="dot-pulse dot-white"></div>
   </div>:
      <div className={` ${styles.property}`}>
        {/* <Header loggedIn></Header> */}
        <AboveMap />
        <div className={` ${styles.property__map} `}>
          <ImageMapView
            longitude={longitude}
            latitude={latitude}
            selectedMarkers={selectedMarkers}
            toggleMarker={toggleMarker}
            propertyInformation={propertyInformation}
            label={label}
            setSelectedMarkers={setSelectedMarkers}
            propertyfeaturesAsString={propertyfeaturesAsString}
            address={propertyDetails?.mailingAddress ?? ""}
          ></ImageMapView>
        </div>

        <div className={` ${styles.property__tabs}`}>
          <PropertyTabs
          displayText={displayText}
            handleClickSkipTrace={handleClickSkipTrace}
            handleClickAddContact={handleClickAddContact}
            selectedPropertyId={selectedPropertyId || ""}
            type={type}
            showNoteCount={showNoteCount}
            setShowNoteCount={setShowNoteCount}
          />
        </div>
      </div>}
    </>
  );
};

export default PropertyDetails;
