export const getMarginCategory = (margin: any): string => {
    if (margin < 7) return "Bad Margins";
    if (margin >= 7 && margin <= 9) return "Low Margins";
    if (margin >= 10 && margin <= 14) return "Profitable";
    if (margin >= 15) return "Money Maker";
    return "-";
  };
  
  export const getMarginColor = (margin: any): string => { 
    let newNum=Number(margin)
    if (newNum < 7) return "red"; 
    if (newNum >= 7 && newNum <= 9) return "yellow"; 
    if (newNum >= 10 && newNum <= 14) return "green"; 
    if (newNum >= 15) return "gold";
    return "-";
  };