import { useFormContext } from "react-hook-form";
import SearchReflation from "../../../assets/icons/searchReflation";
import DateRangePickerCalendar from "../../../atoms/DateRangePickerCalendar";
import Input from "../../../atoms/Input";
import MultiSelectDropDown from "../../../atoms/MultiSelectDropDown";
import {
  ABSENTEE_OWNER_OPTIONS,
  OCCUPANCY_OPTIONS,
  OWNER_TYPE_OPTIONS,
} from "../constants";
import { useEffect } from "react";

const Ownership = ({ onInputBlur }: { onInputBlur: Function }) => {
  const {
    formState: { errors },
    control,
    register,
    getValues
  }: any = useFormContext();
  const methods = useFormContext();

  useEffect(() => {
    let quickFilters = getValues("quickFilters");
    const updateQuickFilters = (filter:any, condition:any) => {
      if (condition) {
        if (!quickFilters.includes(filter)) {
          quickFilters.push(filter);
        }
      } else {
        quickFilters = quickFilters.filter((item:string) => item !== filter);
      }
    };
  
    const occupancyFilters = methods.watch("ownershipFilter.occupancy");
    updateQuickFilters("OWNER_OCCUPIED", occupancyFilters?.includes("OWNER_OCCUPIED"));
    updateQuickFilters("VACANT", occupancyFilters?.includes("VACANT"));

    const absenteeOwnerFilters = methods.watch("ownershipFilter.absenteeOwner");
    updateQuickFilters("IN_STATE_ABSENTEE_OWNER", absenteeOwnerFilters?.includes("IN_STATE_ABSENTEE_OWNER"));
    updateQuickFilters("OUT_OF_STATE_ABSENTEE_OWNER", absenteeOwnerFilters?.includes("OUT_OF_STATE_ABSENTEE_OWNER"));
    
    const ownerTypeFilters = methods.watch("ownershipFilter.ownerType");
    updateQuickFilters("CORPORATE_OWNED", ownerTypeFilters?.includes("CORPORATE"));
  
    methods.setValue("quickFilters", quickFilters);
  }, [
    methods.watch("ownershipFilter.occupancy"),
    methods.watch("ownershipFilter.absenteeOwner"),
    methods.watch("ownershipFilter.ownerType")
  ]);
  
  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Owner Type</p>
          <MultiSelectDropDown
            options={OWNER_TYPE_OPTIONS}
            label="Any"
            name={"ownershipFilter.ownerType"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
        <div className={`colRow__col`}>
          <p>Purchase Date</p>

          <DateRangePickerCalendar
            placeHolder="Select dates"
            control={control}
            name="ownershipFilter.lastSaleDateRange"
            errors={errors?.["ownershipFilter"]?.["lastSaleDateRange"]}
          />

          {/* <CustomDateRangePicker
              placeHolder="Select dates"
              control={control}
              name="ownershipFilter.lastSaleDateRange"
              errors={errors?.["ownershipFilter"]?.["lastSaleDateRange"]}
            ></CustomDateRangePicker> */}
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Years Owned</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="ownershipFilter.yearsOwnedMin"
                placeholder="No Min"
                errors={errors?.["ownershipFilter"]?.["yearsOwnedMin"]}
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="numeric"
                register={register}
                name="ownershipFilter.yearsOwnedMax"
                placeholder="No Max"
                errors={errors?.["ownershipFilter"]?.["yearsOwnedMax"]}
                control={control}
                errorToolTip={true}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Purchase Price ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="ownershipFilter.lastSalePriceMin"
                placeholder="No Min"
                errors={errors?.["ownershipFilter"]?.["lastSalePriceMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                type="currency"
                register={register}
                name="ownershipFilter.lastSalePriceMax"
                placeholder="No Max"
                errors={errors?.["ownershipFilter"]?.["lastSalePriceMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
                onInputBlur={onInputBlur}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Absentee Owner</p>
          <MultiSelectDropDown
            options={ABSENTEE_OWNER_OPTIONS}
            label="Any"
            name={"ownershipFilter.absenteeOwner"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
        <div className={`colRow__col`}>
          <p>Occupancy</p>
          <MultiSelectDropDown
            options={OCCUPANCY_OPTIONS}
            label="Any"
            name={"ownershipFilter.occupancy"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
      </div>
    </>
  );
};
export default Ownership;
