import React, { useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button";
import styles from "./VaPlanModal.module.scss";
import ArrowDown from "../../assets/icons/arrowDown";
import { VaPlanLegalProps } from "./interface";

const VaPlanTerm = ({ onSubmit, onCancel,confirmSubscriptionLoading }: VaPlanLegalProps) => {
  const [scrollPosition, setScrollPosition] = useState(false);
  const bottomRef = useRef<HTMLParagraphElement>(null);
  const isInitialScrollPositionSet = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isInitialScrollPositionSet.current && entry.isIntersecting) {
          setScrollPosition(true);
          isInitialScrollPositionSet.current = true;
        }
      },
      { threshold: 0.5 }
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [bottomRef]);

  return (
    <div className={`${styles.createCampaign} ${styles.vaTerm}`}>
      <div className={` ${styles.vaTerm__header}`}>
        <h3>Review and Accept VA Calling Subscription Terms</h3>
      </div>
      <div className={`${styles.vaTerm__info}`}>
        <p>
          Please review and accept the below terms related to subscribing to and
          utilizing the VA Calling services provided by OttoLeads.
        </p>
        <p>
          YOU AGREE TO ABIDE BY OUR TERMS OF USE WHEN SUBSCRIBING TO A VA
          CALLING SERVICE AND MAKING USE OF THE SERVICES PROVIDED BY OTTOLEADS
        </p>
        <p>
          Pursuant to the actions herein described, comprising the uploading of
          a list of one or several phone numbers to the digital infrastructure
          of OttoLeads, or in the alternative, the acquisition of one or several
          lists of phone numbers from OttoLeads, with the intent to engage the
          VA Calling services provided by OttoLeads, I, the subscriber to the VA
          Calling services, do hereby acknowledge and unequivocally accept the
          unassailable obligation, which devolves solely upon myself, to
          procure, in a manner consistent with prevailing legal standards and
          regulations, all necessary permissions and consents prerequisite for
          the initiation and making of any forms of communication, whether
          direct or indirect, with the parties delineated in the aforementioned
          list(s) of phone numbers. This obligation is understood to encompass
          the comprehensive assurance that all such communicative activities
          conform to, and do not contravene, any and all relevant statutory
          requirements and regulatory provisions.
        </p>
        <p>
          Furthermore, I acknowledge and accept that any telecommunication
          account established under my name and Employer Identification Number
          (EIN) for the purposes of utilizing VA Calling services is solely
          owned and controlled by myself. Any Virtual Assistants (VAs) employed
          by OttoLeads who log in to such accounts do so exclusively under my
          direction and on my behalf. OttoLeads acts solely as a service
          provider and shall not be deemed to assume any responsibility for
          compliance with telemarketing laws or other regulatory provisions in
          connection with such accounts or services.
        </p>
        <p>
          I further acknowledge and agree that the engagement of Virtual
          Assistants (VAs) employed by OttoLeads for the purposes of conducting
          telecommunication activities under my account shall not create, and
          shall not be construed to create, any agency, partnership, joint
          venture, or employment relationship between myself and OttoLeads.
          OttoLeads expressly disclaims any such relationship and affirms that
          its role is limited to providing independent contractor services to
          facilitate my telecommunication activities as authorized by me. I
          understand and agree that all actions taken by OttoLeads or its
          employees in relation to the VA Calling services are performed solely
          at my discretion and under my explicit direction, and that I retain
          full control and responsibility for such activities.
        </p>
        <p>
          OttoLeads expressly disclaims any and all liability, and shall not be
          deemed to assume any responsibility or obligation, whether direct,
          indirect, implicit, or statutory, in relation to the procurement,
          verification, or assurance of the necessary consents, permissions, or
          authorizations which may be required under applicable laws,
          regulations, or standards for the initiation, continuation, or conduct
          of any form of communication with individuals and their phone numbers
          identified in any list uploaded to or procured from OttoLeads for the
          purpose of utilizing its services.
        </p>
        <p>
          It is my sole and exclusive duty, not OttoLeads, to ensure that all
          such consents, permissions, or authorizations have been lawfully
          obtained, in full compliance with the relevant legal and regulatory
          provisions governing such activities. This includes, but is not
          limited to, compliance with the Telephone Consumer Protection Act
          (TCPA), Federal Communications Commission (FCC) regulations, and any
          applicable federal, state, or local telemarketing laws.
        </p>
        <p>
          In addition, I agree to indemnify, defend, and hold harmless
          OttoLeads, LLC, its affiliates, officers, employees, and agents from
          and against any and all claims, liabilities, damages, losses, costs,
          or expenses (including reasonable attorney's fees) arising from or
          related to:
        </p>

        <ul className={`${styles.vaTerm__infoList}`}>
         <li> 1. Any telemarketing activities conducted using my VA Calling
          subscription.</li> <li> 
          2. Any alleged or actual violation of TCPA, FCC, or
          other applicable laws arising from my telephonic communications or
          lists uploaded/provided for use. </li> <li> 
          3. Any claims brought by third parties, including governmental entities, related to telemarketing
          activities performed under my account.</li> 
          </ul>
          <p ref={bottomRef}>
          By subscribing to and using the
          VA Calling services, I affirm that I have read, understood, and agreed
          to these terms and conditions. I also confirm that I will ensure
          compliance with all applicable laws and regulations.
          </p>
       
      </div>
      <div className={`${styles.vaTerm__footer}`}>
        <div className={`${styles.buttons}`}>
          <div className={`${styles.buttons__first}`}>
            <Button
              className="outline xl full animated"
              label={"Cancel"}
              action={onCancel}
            ></Button>
          </div>
          <div className={`${styles.buttons__last}`}>
            <Button
              className="primary xl full animated"
              label={scrollPosition ? "I Agree" : "Scroll down"}
              labelNormal={scrollPosition ? "" : "to accept"}
              prefix={scrollPosition ? "" : <ArrowDown />}
              action={onSubmit}
              clicked={confirmSubscriptionLoading}
              disabled={!scrollPosition || confirmSubscriptionLoading}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaPlanTerm;
