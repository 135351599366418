import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Input from "../../../atoms/Input";
import { useEffect } from "react";

const CashBuyers = ({ onInputBlur }: { onInputBlur: Function }) => {
  const {
    formState: { errors },
    control,
    register,
    getValues
  }: any = useFormContext();
  const methods = useFormContext();

  useEffect(() => {
    let quickFilters = getValues("quickFilters") 
    const cashBuyerFilters = methods.watch("cashBuyersFilter.cashBuyer");
    if (cashBuyerFilters === true) {
      quickFilters.push("CASH_BUYER");
    } else {
      quickFilters = quickFilters.filter((filter: string) => filter !== "CASH_BUYER");
    }
    methods.setValue("quickFilters", quickFilters);
  }, [
    methods.watch("cashBuyersFilter.cashBuyer")
  ]);

  return (
    <>
      <div className={`colRow`}>
        <div className={`colRow__full`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="cashBuyersFilter.cashBuyer"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Cash Purchase</h5>
                  <p>
                    Properties where property ownership is subsequent to an all
                    cash transaction.
                  </p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Properties Owned</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="numeric"
                register={register}
                name="cashBuyersFilter.propertiesOwnedMin"
                placeholder="No Min"
                errors={errors?.["cashBuyersFilter"]?.["propertiesOwnedMin"]}
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="numeric"
                register={register}
                name="cashBuyersFilter.propertiesOwnedMax"
                placeholder="No Max"
                errors={errors?.["cashBuyersFilter"]?.["propertiesOwnedMax"]}
                control={control}
                errorToolTip={true}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Portfolio Value ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="cashBuyersFilter.portfolioValueMin"
                placeholder="No Min"
                errors={errors?.["cashBuyersFilter"]?.["portfolioValueMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="cashBuyersFilter.portfolioValueMax"
                placeholder="No Max"
                errors={errors?.["cashBuyersFilter"]?.["portfolioValueMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Portfolio Equity ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="cashBuyersFilter.portfolioEquityMin"
                placeholder="No Min"
                errors={errors?.["cashBuyersFilter"]?.["portfolioEquityMin"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="cashBuyersFilter.portfolioEquityMax"
                placeholder="No Max"
                errors={errors?.["cashBuyersFilter"]?.["portfolioEquityMax"]}
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
          </div>
        </div>
        <div className={`colRow__col`}>
          <p>Portfolio Debt ($)</p>
          <div className={`filterRow`}>
            <div className={`filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="cashBuyersFilter.portfolioMortgageBalanceMin"
                placeholder="No Min"
                errors={
                  errors?.["cashBuyersFilter"]?.["portfolioMortgageBalanceMin"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
            <span className="separate">-</span>
            <div className={` filterRow__col`}>
              <Input
                onInputBlur={onInputBlur}
                type="currency"
                register={register}
                name="cashBuyersFilter.portfolioMortgageBalanceMax"
                placeholder="No Max"
                errors={
                  errors?.["cashBuyersFilter"]?.["portfolioMortgageBalanceMax"]
                }
                control={control}
                errorToolTip={true}
                onKeyDown={(e: any) => {
                  if (e?.key?.toLowerCase() === "e") {
                    e?.preventDefault();
                  }
                }}
              ></Input>
            </div>
          </div>
        </div>
      </div>
  
    </>
  );
};
export default CashBuyers;
