import { Tooltip } from "@mui/material";
import styles from "./InfoCard.module.scss";
import { InfoCardProps } from "./interface";
import InfoCircle from "../../assets/icons/infoCircle";
import { getMarginCategory, getMarginColor } from "./constants";

const InfoCard = ({ title, description,icon,descriptionTooltip }: InfoCardProps) => {

    return (
        <div className={`${styles.infoCard}`}>
            <h4>{title}
                {icon?   <Tooltip title="Assuming 7% cost of sale" placement="top">
                        <span>
                          <InfoCircle />
                        </span>
                      </Tooltip>:""}
            </h4>
            <p className={`${descriptionTooltip? styles[getMarginColor(description)] :""}`}>{descriptionTooltip ? <Tooltip title={`${getMarginCategory(description)}`} placement="top">
                        <span>
                        {descriptionTooltip}
                        </span>
                      </Tooltip>:description}</p>
       
        </div>

    );
};

export default InfoCard;