import { Dialog, Tooltip } from "@mui/material";
import style from "./ManageSubscription.module.scss";
import Button from "../../atoms/Button";
import Zap from "../../assets/icons/zap";
import Close from "../../assets/icons/close";
import CheckCircleFill from "../../assets/icons/CheckCircleFill";
import { useDispatch, useSelector } from "react-redux";
import { getCreditUsage, getSubscriptions, getSubscriptionsIsLoading } from "./selectors";
import Loader from "../../atoms/Loader";
import dayjs from "dayjs";
import { ProductPriceIntervalType, ProductType } from "./enum";
import InfoCircle from "../../assets/icons/infoCircle";
import { ManageSubscriptionProps } from "./interface";
import PauseSubscription from "./PauseSubscription";
import { useEffect, useState } from "react";
import { resumeSubscription } from "./billingService";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { CustomError } from "../addLeads/interface";
import { setSubscriptions } from "./reducer";
import { fetchCreditUsages } from "./sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";

const NoSubscriptionsFallback = ({ label, productType, onBuyPlan }: any) => {
  return (
    <>
      <div className={`${style.manage__item} dflex alignCenter`}>
        <div className={`${style.manage__info} flex alignCenter`}>
          <span className={`animated-svg color-red`}>
            <InfoCircle />
          </span>
          <aside>
            <strong>{label}</strong>
            <p>No Active Subscription</p>
          </aside>
        </div>
        <div className={`${style.manage__action} flex`} data-testid="buySubscription">
          <Button
            label="Buy Subscription"
            className="button primary md "
            action={() => onBuyPlan(productType)}
          />
        </div>
      </div>
    </>
  );
};

const ManageSubscription = ({
  handleClose,
  open,
  onCancelSubscription,
  onUpgradeNow,
  onBuySubscription,
}: ManageSubscriptionProps) => {
  const subscriptionIsLoading = useSelector((state) =>
    getSubscriptionsIsLoading(state)
  );
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const onBuyPlan = (type: any) => {
    onBuySubscription(type);
  };
  const dispatch= useDispatch()
  const creditUsage = useSelector((state) => getCreditUsage(state));
  const [pauseModal, setPauseModal]= useState(false);
  const [confirmPauseModal, setConfirmPauseModal]= useState(false);
  const [loading, setLoading] = useState(false);
  const profileInfo = useSelector((state: any) => getProfileInfo(state));

  const handlePauseModal=()=>{
    setPauseModal(!pauseModal)
    setConfirmPauseModal(true)
  }

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  const handleResume=async()=>{
    try {
      setLoading(true);
      await resumeSubscription(sunscriptions?.creditSubData?.stripeSubscriptionId);
      setLoading(false);
      handleShowToast("Subscription resumed successfully!", ToastType.success);
      dispatch(setSubscriptions({
        ...sunscriptions,
        creditSubData: {
          ...sunscriptions?.creditSubData,
          isPaused: false
        }
      }));
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  }

  useEffect(()=>{
    if(open){
    dispatch(fetchCreditUsages({}));}
  },[open])

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog subscriptionDialog manageSubscription`}
      >
        {subscriptionIsLoading ? (
          <div className={`loader-main`}>
            <Loader></Loader>
          </div>
        ) : (
          <div className={style.manage}>
            <div className={`${style.manage__header} flex justifySpaceBetween`}>
              <CheckCircleFill />
              <span role="link" className="closeDialog" onClick={handleClose}>
                <Close />
              </span>
            </div>
            <h4>Manage your subscriptions</h4>

            { profileInfo.organisation.isVaAccess ? sunscriptions?.vaSubData ? (
              <>
                <div className={`${style.manage__item} dflex alignCenter`}>
                  <div className={`${style.manage__info} flex alignCenter`}>
                    {ProductPriceIntervalType.MONTH ===
                    sunscriptions?.vaSubData?.interval ? (
                      <span className={`animated-svg `}>
                        <InfoCircle />
                      </span>
                    ) : (
                      <span className={`animated-svg color-red`}>
                        <InfoCircle />
                      </span>
                    )}
                    <aside>
                      <strong>
                        {sunscriptions?.vaSubData?.productName}
                      </strong>
                      <p>
                        Renews{" "}
                        {sunscriptions?.vaSubData?.expiresAt
                          ? dayjs(
                              sunscriptions?.vaSubData?.expiresAt
                            ).format(`MMM DD, YYYY`)
                          : "-"}
                      </p>
                    </aside>
                  </div>

                  <div className={`${style.manage__action} flex`}>
                    <Button
                      label="Cancel"
                      className="outline md"
                      action={() =>
                        onCancelSubscription(sunscriptions?.vaSubData)
                      }
                    />
                    
                  </div>
                </div>
              </>
            ) : (
              <NoSubscriptionsFallback
                label="VA Plan"
                productType={ProductType.VA_ACCESS}
                onBuyPlan={onBuyPlan}
              ></NoSubscriptionsFallback>
            ) :<></>}
            {sunscriptions?.creditSubData ? (
              <>
                <div className={`${style.manage__item} dflex alignCenter`}>
                  <div className={`${style.manage__info} flex alignCenter`}>
                    <span className={`animated-svg `}>
                      <InfoCircle />
                    </span>
                    <aside>
                      <strong>
                        {sunscriptions?.creditSubData?.productName}
                      </strong>
                      <p>
                        {sunscriptions?.creditSubData?.isPaused ? "" :`Renews ${' '}`}
                        {sunscriptions?.creditSubData?.isPaused ? "Paused" :sunscriptions?.creditSubData?.expiresAt
                          ? dayjs(
                              sunscriptions?.creditSubData?.expiresAt
                            ).format(`MMM DD, YYYY`)
                          : "-"}
                      </p>
                    </aside>
                  </div>

                  <div className={`${style.manage__action} flex `}>
                      <Button
                        label="Cancel"
                        className="outline md"
                        action={() =>
                          onCancelSubscription(
                            sunscriptions?.creditSubData,
                            ProductType.CREDIT_ACCESS
                          )
                        }
                      />
                    {sunscriptions?.creditSubData?.isPaused ? (
                      <Button
                        label="Resume"
                        className="outline md"
                        action={() => {
                          handleResume();
                        }}
                        clicked={loading}
                        disabled={loading}
                      />
                    ) : (
                      <Button
                        label="Pause"
                        className="outline md"
                        action={() => {
                          handlePauseModal();
                        }}
                      />
                    )}
                    {!(
                      ProductPriceIntervalType.YEAR ===
                        sunscriptions?.creditSubData?.interval &&
                      ProductType.GENERAL_ACCESS ===
                        sunscriptions?.creditSubData?.productType
                    ) &&
                    
                        <Button
                          prefix={<Zap />}
                          label="Upgrade now"
                          className="button primary md "
                          action={() =>
                            onUpgradeNow(sunscriptions?.creditSubData)
                          }
                        />
                      }
                  </div>
                </div>
              </>
            ) : (
              sunscriptions?.generalSubData && (
                <NoSubscriptionsFallback
                  label="AI Calling Subscription"
                  productType={ProductType.CREDIT_ACCESS}
                  onBuyPlan={onBuyPlan}
                ></NoSubscriptionsFallback>
              )
            )}
            {sunscriptions?.generalSubData ? (
              <>
                <div className={`${style.manage__item} dflex alignCenter`}>
                  <div className={`${style.manage__info} flex alignCenter`}>
                    {ProductPriceIntervalType.YEAR ===
                    sunscriptions?.generalSubData?.interval ? (
                      <span className={`animated-svg `}>
                        <InfoCircle />
                      </span>
                    ) : (
                      <span className={`animated-svg color-red`}>
                        <InfoCircle />
                      </span>
                    )}
                    <aside>
                      <strong>
                        {sunscriptions?.generalSubData?.productName}
                      </strong>
                      <p>
                        Renews{" "}
                        {sunscriptions?.generalSubData?.expiresAt
                          ? dayjs(
                              sunscriptions?.generalSubData?.expiresAt
                            ).format(`MMM DD, YYYY`)
                          : "-"}
                      </p>
                    </aside>
                  </div>

                  <div className={`${style.manage__action} flex`}>
                    <Button
                      label="Cancel"
                      className="outline md"
                      action={() =>
                        onCancelSubscription(sunscriptions?.generalSubData)
                      }
                    />
                    {ProductPriceIntervalType.MONTH ===
                      sunscriptions?.generalSubData?.interval && (
                      <Button
                        prefix={<Zap />}
                        label={`Switch to Yearly and Save`}
                        className="button primary md "
                        action={() =>
                          onUpgradeNow(
                            sunscriptions?.generalSubData,
                            ProductType.GENERAL_ACCESS
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <NoSubscriptionsFallback
                label="General access Subscription"
                productType={ProductType.GENERAL_ACCESS}
                onBuyPlan={onBuyPlan}
              ></NoSubscriptionsFallback>
            )}
          </div>
        )}
      </Dialog>
      <PauseSubscription
        handleClose={() => {
          setPauseModal(false);
        }}
        open={pauseModal}
        setPause={setPauseModal}
        subscriptions={sunscriptions}
        confirmPauseModal={confirmPauseModal} 
        setConfirmPauseModal={setConfirmPauseModal}
        creditUsage={creditUsage}
      ></PauseSubscription>
    </>
  );
};

export default ManageSubscription;
