  export const callAttemptsOptions = {
    "Left Voicemail": "LeftVoiceMail",
    "Disconnected": "Disconnected",
    "Wrong Number": "WrongNumber",
    "Do Not Pick Up": "DoNotPickUp",
    "Hung-up": "HungUp",
    "Not Interested": "NotInterested",
    "DNC": "DNC",
    "Call Back Later": "CallBackLater",
    "Verified": "Verified",
  } as const;