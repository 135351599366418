import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import styles from "./CallAttemptsNote.module.scss";
import { NOTE_TEMPLATE } from "./constants";

const CallAttemptsNote = ({
  onSend,
  detail,
  newAttempt,
  edit,
  template
}: {
  onSend: Function;
  detail?: any;
  newAttempt: boolean;
  edit?: any;
  template?:""
}) => {
  const [editorVisible, setEditorVisible] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (newState: EditorState) => {
    setEditorState(newState);
    const rawContentState = convertToRaw(newState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    if (rawContentState.blocks.some((block) => block.text.trim() !== "")) {
      onSend(htmlContent);
    } else {
      onSend("");
    }
  };

  const handleEditMenuItemClick = useCallback((message: any) => {
    if (!message) return;
    const { contentBlocks, entityMap } = convertFromHTML(message);
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );
    handleEditorStateChange(newEditorState);
    setEditorVisible(true);
  }, []);

  const initializeEditorState = (data:any="") => {
    const blocksFromHTML = convertFromHTML(data);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    if(!detail){
     setEditorVisible(true);
    onSend(data);
    setEditorState(EditorState.createWithContent(contentState))}
  };

  useEffect(()=>{
    if(template){
    initializeEditorState(template)
  }
  },[template])
  useEffect(() => {
    if (edit) {
      if (detail?.summary) {
        handleEditMenuItemClick(detail?.summary);
      } else {
        setEditorState(EditorState.createEmpty());
      }
      setEditorVisible(true);
    }
  }, [edit, detail]);

  return (
    <div className={` ${styles.note}`}>
      {detail && !edit ? (
        detail.summary ? (
          <div className={` ${styles.note__summary}`}>
            <div
              className={`dflex justifySpaceBetween alignCenter ${styles.note__header}`}
            >
              <h5>{detail?.createdByName}</h5>
              <p> {new Date(detail?.updatedAt).toLocaleString()}</p>
            </div>
            <div
              className={` ${styles.note__summaryText}`}
              dangerouslySetInnerHTML={{
                __html: detail.summary,
              }}
            ></div>
          </div>
        ) : (
          ""
        )
      ) : (
        !newAttempt && (
          <div className={` ${styles.note__add}`}>
            <div id="editor">
              <Editor
                editorState={editorState}
                wrapperClassName="wrapperClassName"
                editorClassName="lead-activity-editor"
                onEditorStateChange={handleEditorStateChange}
                handlePastedText={() => {
                  setTimeout(() => {
                    const editorNode = document.getElementsByClassName(
                      "lead-activity-editor"
                    )[0];
                    editorNode.scroll(0, editorNode.scrollHeight);
                  }, 0);
                  return false;
                }}
                toolbar={{
                  options: ["inline", "list"],
                  list: { options: ["ordered", "unordered"] },
                }}
                spellCheck={true}
              />
            </div>
          </div>
        )
      )}
      {newAttempt && (
        <div className={` ${styles.note__add}`}>
          {!editorVisible && (
            <textarea
              placeholder="Write something or Select template from above dropdown"
              onClick={() => {
                setEditorVisible(true);
                setEditorState(EditorState.moveFocusToEnd(editorState));
              }}
            />
          )}
          {editorVisible && (
            <div id="editor">
              <Editor
                editorState={editorState}
                wrapperClassName="wrapperClassName"
                editorClassName="lead-activity-editor"
                onEditorStateChange={handleEditorStateChange}
                handlePastedText={() => {
                  setTimeout(() => {
                    const editorNode = document.getElementsByClassName(
                      "lead-activity-editor"
                    )[0];
                    editorNode.scroll(0, editorNode.scrollHeight);
                  }, 0);
                  return false;
                }}
                toolbar={{
                  options: ["inline", "list"],
                  list: { options: ["ordered", "unordered"] },
                }}
                spellCheck={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CallAttemptsNote;
