import { useEffect, useState } from "react";
import CustomTable from "../../molecules/table/CustomTable";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import {
  fetchActiveUsersList,
  fetchPendingUsersList,
} from "../../pages/myTeam/sagaActions";
import {
  getActiveListLoading,
  getActiveUserList,
  getPendingUserList,
  getUserRoleList,
} from "../../pages/myTeam/selectors";
import { updateUserRole } from "../../pages/myTeam/TeamService";
import { MANAGE_TEAM_TABLE_HEADER } from "./constants";
import ManageTeamActions from "./ManageTeamActions";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../auth/enum";

const ActiveUsers = () => {
  const activeList = useSelector((state) => getActiveUserList(state));
  const dataLoading = useSelector((state) => getActiveListLoading(state));
  const pendingList = useSelector((state) => getPendingUserList(state));
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const roleList = useSelector((state) => getUserRoleList(state));
  const [roleListOptions, setRoleListOptions] = useState([]);

  useEffect(() => {
    const options = roleList.filter((list: any) => list !== 'VA').map((list: any) => ({
      value: list,
      label: list,
    }));
    setRoleListOptions(options);
  }, [roleList]);

  const displayToast = (message: string, type: ToastType) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleStatusChange = async (list: any) => {
    const payload = {
      id: list.id,
      userRoleName: list?.value,
      isApprovedUser: list?.flag,
    };
    console.log(payload);

    try {
      await updateUserRole(payload);
      displayToast("User role updated successfully!", ToastType.success);
    } catch (err:any) {
      console.log(err);
      displayToast(err?.message, ToastType.error);
    }
  };
  useEffect(() => {
    const listData = [...pendingList, ...activeList]?.map((activeUser: any) => {
      return {
        id: activeUser?.id,
        name: `${activeUser?.nameFirst} ${
          activeUser?.nameLast ? activeUser?.nameLast : ""
        }`,
        email: activeUser?.email,
        role: (profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER || profileInfo?.email === activeUser?.email || activeUser?.userRoleName === PermissionListEnum.ORGANISATION_VA )? true :false ,
        roleName: activeUser?.userRoleName ,
        flag: activeUser?.status === "PENDING" ? false : true,
        rootUser: activeUser?.isRootUser  || activeUser?.userRoleName ==="VA" ? true: false,
        actions: (
          <ManageTeamActions activeUser={activeUser} key={activeUser?.id} />
        ),
        activityDrawerLeadId: false,
      };
    });
    setTableData(listData ?? []);
  }, [activeList, pendingList, profileInfo]);

  useEffect(() => {
    dispatch(fetchActiveUsersList({}));
    dispatch(fetchPendingUsersList({}));
  }, []);

  return (
    <>
      {dataLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className="manage-team">
          <CustomTable
            rows={tableData}
            columns={MANAGE_TEAM_TABLE_HEADER}
            search={""}
            tableStriped
            // hasMore={tableData.length < total}
            options={roleListOptions}
            hasMore={false}
            handleClickedPill={handleStatusChange}
          ></CustomTable>
        </div>
      )}
    </>
  );
};

export default ActiveUsers;
