import { getAPI, postAPI, putAPI } from "../../apis/apis";
import { PropertyDetailsParentIdType } from "./property.enum";

export const postWholesaleCalculator = async (payload: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-calculator/wholesale`,
    headers: {},
    data: payload,
  });
};

export const postAmortizationCalculator = async (payload: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-calculator/amortization`,
    headers: {},
    data: payload,
  });
};

export const postFinanceCalculator = async (payload: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-calculator/finance`,
    headers: {},
    data: payload,
  });
};

export const updateFinanceCalculator = async (payload: any, id: any) => {
  return putAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-calculator/finance/${id}`,
    headers: {},
    data: payload,
  });
};

export const createNote = async (id: any, data: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/lead/${id}/notes`,
    headers: {},
    data: data,
  });
};

export const fetchNotes = async (id: any, pageNumber: number,count:number=5) => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/lead/${id}/notes?skip=${pageNumber}&count=${count}`,
    headers: {},
  });
};

export const updatePropertyDetails = (data: {
  id: string;
  payload: any;
  type: PropertyDetailsParentIdType;
}) => {
  return putAPI({
    url: `${process.env.REACT_APP_BASE_URL}/property-update?${data.type}=${data.id}`,
    data: data.payload,
    headers: {},
  });
};

export const fetchNoteCount = async (id: any) => {
  return getAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/aggregate/count/${id}`,
    headers: {},
  });
};

export const editCallAttempt = async (id: any, data: any) => {
  return postAPI({
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/lead/${id}/callAttemptLog`,
    headers: {},
    data: data,
  });
};