import { Dialog } from "@mui/material";
import React, { PropsWithChildren, useEffect } from "react";
import { CustomModalProps } from "./interface";

const CustomModal = ({
  open,
  handleClose,
  children,
  customClass,
}: PropsWithChildren<CustomModalProps>) => {

  useEffect(()=>{
    if (open) {
      document.body.classList.add("modal-open");
    }else{
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  },[open])
  return (
    <Dialog className={`stepsDialog ${customClass ? customClass : 'propertyDetail'}`} onClose={handleClose} open={open}>
        {children}
    </Dialog>
  );
};

export default CustomModal;
