import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Refresh from "../../assets/icons/refresh";
import Rocket from "../../assets/icons/rocket";
import Button from "../../atoms/Button";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import DashboardHeaderBar from "../../components/dashboardHeaderBar/DashboardHeaderBar";
import { CampaignStatusType } from "../../components/dashboardHeaderBar/enum";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { SortDirection } from "../../molecules/table/enum";
import AnalyticCards from "../../organisms/analyticCards/AnalyticCards";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { getSubscriptions } from "../../organisms/billing/selectors";
import { fetchCampaignExportList } from "../../organisms/campaignTable/campaignService";
import CampaignTable from "../../organisms/campaignTable/campaignTable";
import { resetCampaignState } from "../../organisms/campaignTable/reducer";
import { getCampaignStats } from "../../organisms/campaignTable/selectors";
import CallBackNo from "../../organisms/createCampaign/CallBackNo";
import CreateCampaign from "../../organisms/createCampaign/CreateCampaign";
import { resetCreateCampaignState } from "../../organisms/createCampaign/reducer";
import { fetchCampaignLeadList } from "../../organisms/createCampaign/sagaActions";
import { getCampaignCreated } from "../../organisms/createCampaign/selectors";
import HeroSection from "../../organisms/heroSection/HeroSection";
import { UpgradeModalParentType } from "../../organisms/upgradeModal/enum";
import UpgradeModal from "../../organisms/upgradeModal/UpgradeModal";
import {
  setNewListUploaded,
  toggleImportFileModal,
} from "../../organisms/uploadList/reducer";
import {
  getNewListUploaded,
  getOpenImportFileModal,
} from "../../organisms/uploadList/selectors";
import UploadList from "../../organisms/uploadList/UploadList";
import {
  campaignRefreshTimeFormatter,
  fbc,
  fbp,
  fetchIp,
  formartNumberAsLocalString,
  formatNumber,
  hashNumber,
} from "../../utils/utils";
import styles from "./Campaigns.module.scss";
import { cards, columns } from "./constants";
export interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
  checked: boolean;
  status: string;
  id: number;
}

const Campaigns = () => {
  const [openCreateCampaign, setOpenCreateCampaign] = useState<boolean>(false);
  const [campaignFilter, setCampaignFilter] = useState<{
    searchText: string;
    pageNumber: number;
    limit: number;
    filter: string;
    sortDirection: string;
    sortKey: string;
  }>({
    searchText: "",
    pageNumber: 1,
    limit: 20,
    filter: CampaignStatusType.ALL,
    sortDirection: SortDirection.DESC,
    sortKey: "",
  });

  const dispatch = useDispatch();
  const [exportListLoading, setExportListLoading] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const campaignCreated = useSelector((state) => getCampaignCreated(state));
  const openUploadListModal = useSelector((state) =>
    getOpenImportFileModal(state)
  );

  const newListAdded = useSelector((state) => getNewListUploaded(state));
  const [refreshTime, setSefreshTime] = useState<String>();
  const [updateTime, setUpdateTime] = useState<Date>(new Date());
  const clear = useRef<any>();
  const location = useLocation();
  const { selectedListId } = location.state || {};
  const [openCallBackNoModal, setOpenCallBackNoModal] =
    useState<boolean>(false);
  const campaignStats = useSelector((state) => getCampaignStats(state));
  const subscription = useSelector((state) => getSubscriptions(state));
  const [openPlanUpgradeModal, setOpenPlanUpgradeModal] = useState(false);
  const [upgradSubscriptionProduct, setUpgradSubscriptionProduct] =
    useState(null);
  const setSearchText = (value: string) => {
    setCampaignFilter((prev) => ({
      ...prev,
      searchText: value,
      pageNumber: 1,
    }));
  };
  const handleNextPage = () => {
    setCampaignFilter((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
  };

  const handleUploadList = () => {
    dispatch(toggleImportFileModal(true));
  };

  const dispatchToggleImportFileModalAction = (state: boolean) => {
    dispatch(toggleImportFileModal(state));
  };

  const onActionClick = (item: any) => {
    console.log(item);
  };

  const handleRefresh = () => {
    setUpdateTime(new Date());
    setCampaignFilter({
      searchText: "",
      pageNumber: 1,
      limit: 20,
      filter: CampaignStatusType.ALL,
      sortDirection: SortDirection.DESC,
      sortKey: "",
    });
  };

  const handleFilterChange = (filter: string) => {
    setCampaignFilter((prev) => ({
      searchText: "",
      pageNumber: 1,
      limit: 20,
      filter: filter,
      sortDirection: SortDirection.DESC,
      sortKey: "",
    }));
  };

  const handleChange = async () => {
    try {
      setExportListLoading(true);
      const result: any = await fetchCampaignExportList({
        filter: "all",
      });
      setExportListLoading(false);
      return result?.data?.result;
    } catch (error) {
      setExportListLoading(false);
    }
  };

  const handleSorting = (sortKey: string | undefined) => {
    if (sortKey) {
      setCampaignFilter((prev) => ({
        searchText: "",
        pageNumber: 1,
        filter: campaignFilter.filter,
        limit: 20,
        sortDirection:
          prev.sortKey === sortKey
            ? prev.sortDirection === SortDirection.DESC
              ? SortDirection.ASC
              : SortDirection.DESC
            : SortDirection.DESC,
        sortKey: sortKey,
      }));
    }
  };
  const createCampaign = () => {
    setOpenCreateCampaign(true);
    dispatch(fetchProfileInfo({}));
  };

  useEffect(() => {
    if (campaignCreated) {
      setUpdateTime(new Date());
      setCampaignFilter({
        searchText: "",
        pageNumber: 1,
        limit: 20,
        filter: CampaignStatusType.ALL,
        sortDirection: SortDirection.DESC,
        sortKey: "",
      });
    }
  }, [campaignCreated]);

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Campaign_view",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(
                  profileInfo?.data?.result?.organisationId
                ),
              },
            },
          ],
        };

        await FbConversionApi(payloadData);
      } catch (error) {
        console.error("Error sending payload:", error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  useEffect(() => {
    if (clear.current) {
      clearInterval(clear.current);
    }
    setSefreshTime(campaignRefreshTimeFormatter(updateTime));
    clear.current = setInterval(() => {
      setSefreshTime(campaignRefreshTimeFormatter(updateTime));
    }, 30000);
    return () => {
      clearInterval(clear.current);
    };
  }, [updateTime]);

  useEffect(() => {
    if (newListAdded) {
      dispatch(fetchCampaignLeadList({}));
      dispatch(setNewListUploaded(false));
    }
  }, [newListAdded]);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetCampaignState({}));
      dispatch(resetCreateCampaignState({}));
    };
  }, [dispatch]);

  useEffect(() => {
    setCampaignFilter((prev) => ({ ...prev }));
  }, [subscription]);

  useEffect(() => {
    if (!selectedListId) return;
    if (!profileInfo?.organisation?.callbackPhone) {
      setOpenCallBackNoModal(true);
    } else {
      createCampaign();
    }
  }, [profileInfo?.organisation?.callbackPhone]);
  
  useEffect(()=>{
 dispatch(fetchProfileInfo({}));
  },[])

  const diffDay = (date: any) => {
    const diffTime = new Date(date).getTime() - new Date().getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
  };

  return (
    <>
      {/* <Header loggedIn></Header> */}
      <HeroSection
        title="Campaign Manager"
        desc="Track, analyze, and create new campaigns."
      />
      <div className="wrapper">
        <DashboardHeaderBar
          searchText={campaignFilter.searchText}
          handleSearchChange={setSearchText}
          handleFilterChange={handleFilterChange}
          selectedFilter={campaignFilter.filter}
        ></DashboardHeaderBar>

        <div className={`dflex ${styles.cards}`}>
          {cards.map((card: any, i: number) => {
            let creditsDueDays = 0;
            if (
              card.title === "Credits Remaining" &&
              campaignStats?.[card.statusKey2]
            ) {
              creditsDueDays = diffDay(campaignStats?.[card.statusKey2]);
            }
            return (
              <div className={`${styles.cards__col}`} key={card.title + i}>
                <AnalyticCards
                  key={card.title + i}
                  title={card.title + ""}
                  value={formatNumber(campaignStats?.[card.key] ?? "-")}
                  statusToday={card.statusToday}
                  statusValue={card.statusValue}
                  status={
                    card.title !== "Credits Remaining"
                      ? campaignStats?.[card.statusKey]
                        ? card.statusToday
                          ? `${formartNumberAsLocalString(
                              campaignStats?.[card.statusKey] || 0
                            )} just today`
                          : `${campaignStats?.[card.statusKey]} running now`
                        : undefined
                      : campaignStats?.[card.statusKey2] &&
                        campaignStats?.[card.statusKey]
                      ? card.statusValue
                        ? creditsDueDays < 1 &&
                          new Date().getDate() ===
                            new Date(campaignStats?.[card.statusKey2]).getDate()
                          ? `Resets to ${formartNumberAsLocalString(
                              campaignStats?.[card.statusKey]
                            )} today`
                          : `Resets to ${formartNumberAsLocalString(
                              campaignStats?.[card.statusKey]
                            )} in ${
                              creditsDueDays < 1
                                ? Math.ceil(creditsDueDays)
                                : Math.floor(creditsDueDays)
                            } days`
                        : undefined
                      : undefined
                  }
                  showStatusKey={true}
                />
              </div>
            );
          })}
        </div>
        <div className={`dflex justifySpaceBetween ${styles.tableTop}`}>
          <div className={`${styles.tableTop__left}`}>
            <Tooltip
              title={
                profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER || profileInfo?.userRoleName ===
                PermissionListEnum.ORGANISATION_VA
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>
                {profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER || profileInfo?.userRoleName ===
                PermissionListEnum.ORGANISATION_VA? (
                  <Button
                    label="Create Campaign"
                    className="primary md"
                    prefix={<Rocket />}
                    disabled={profileInfo?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER || profileInfo?.userRoleName ===
                      PermissionListEnum.ORGANISATION_VA}
                  />
                ) : !profileInfo?.organisation?.callbackPhone ? (
                  <Button
                    label="Create Campaign"
                    className="primary md"
                    prefix={<Rocket />}
                    action={() => setOpenCallBackNoModal(true)}
                  />
                ) : !profileInfo?.organisation?.availableCredit ? (
                  <Button
                    label="Create Campaign"
                    className="primary animated"
                    prefix={<Rocket />}
                    action={() => {
                      setOpenPlanUpgradeModal(true);
                      setUpgradSubscriptionProduct(subscription?.creditSubData);
                    }}
                  />
                ) : (
                  <Button
                    label="Create Campaign"
                    className="primary md"
                    prefix={<Rocket />}
                    action={() => createCampaign()}
                  />
                )}
              </div>
            </Tooltip>
          </div>
          <div className={`flex alignCenter ${styles.tableTop__right}`}>
            <p>Updated {refreshTime}</p>
            <Button
              className="outline icon"
              prefix={<Refresh />}
              action={handleRefresh}
            />
            <ExportToCsv
              headers={columns.map((column) => {
                return { id: column.key, displayName: column.label };
              })}
              filename={`my-file.csv`}
              loadData={() => handleChange()}
              label="Export to CSV"
              loading={exportListLoading}
            ></ExportToCsv>
          </div>
        </div>
        <CampaignTable
          handleNextPage={handleNextPage}
          handleSorting={handleSorting}
          onActionClick={onActionClick}
          handleOpenCreateCampaignModal={() => createCampaign()}
          handleClearSearch={() => setSearchText("")}
          filter={campaignFilter}
        ></CampaignTable>
      </div>
      {openCreateCampaign && (
        <CreateCampaign
          open={openCreateCampaign}
          setOpen={setOpenCreateCampaign}
          handleUploadList={handleUploadList}
        ></CreateCampaign>
      )}

      {openUploadListModal && (
        <UploadList
          open={openUploadListModal}
          closeModal={() => {
            dispatchToggleImportFileModalAction(false);
            createCampaign();
          }}
        ></UploadList>
      )}

      {openCallBackNoModal && (
        <CallBackNo
          open={openCallBackNoModal}
          closeModal={() => setOpenCallBackNoModal(false)}
        ></CallBackNo>
      )}

      <UpgradeModal
        handleClose={() => {
          setOpenPlanUpgradeModal(false);
          setUpgradSubscriptionProduct(null);
        }}
        open={openPlanUpgradeModal}
        product={upgradSubscriptionProduct}
        parentType={UpgradeModalParentType.Usage}
      ></UpgradeModal>
    </>
  );
};

export default Campaigns;
