import { useEffect, useState } from "react";
import styles from "./Billings.module.scss";
import Button from "../../atoms/Button";
import Cloud from "../../assets/icons/cloud";
import Plus from "../../assets/icons/plus";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCardList,
  fetchInvoices,
  fetchSubscriptions,
} from "./sagaActions";
import ManageSubscription from "./ManageSubscription";
import CancelSubscription from "./CancelSubscription";
import { getInvoices, getInvoicesIsLoading, getSubscriptions } from "./selectors";
import InvoiceTable from "./InvoiceTable";
import AddPaymentModal from "./AddPaymentModal";
import ExportToCsv from "../../atoms/ExportToCsv";
import { INVOICE_CSV_HEADER } from "./constant";
import { formartNumberAsLocalString } from "../../utils/utils";
import dayjs from "dayjs";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import UpgradeModal from "../upgradeModal/UpgradeModal";
import CardList from "./CardList";
import Loader from "../../atoms/Loader";
import SubscribeModal from "../subscribeModal/SubscribeModal";
import { ProductPriceIntervalType, ProductType } from "./enum";
import { UpgradeModalParentType } from "../upgradeModal/enum";
import Zap from "../../assets/icons/zap";
import { PermissionListEnum } from "../auth/enum";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { Tooltip } from "@mui/material";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import VaPlanModal from "../vaPlanModal/VaPlanModal";

export const Billings = () => {
  const dispatch = useDispatch();
  const [openManageSubscription, setOpenManageSubscription] = useState(false);
  const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
  const [tableData, setTableData] = useState<any[]>([]);
  const [cancelSubscriptionProduct, setCancelSubscriptionProduct] =
    useState(null);
  const [openPlanUpgradeModal, setOpenPlanUpgradeModal] = useState(false);
  const [openVaPlanModal, setOpenVaPlanModal] = useState(false);
  const [upgradSubscriptionProduct, setUpgradSubscriptionProduct] =
    useState(null);
  const [invoiceFilter, setInvoiceFilter] = useState({
    last_Index: "",
    size: 10,
  });
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const [changeGeneralAccessSubscription, setChangeGeneralAccessSubscription] =
    useState(false);
  const invoices = useSelector((state) => getInvoices(state));
  const invoicesIsLoading = useSelector((state) => getInvoicesIsLoading(state));
  const sunscriptions = useSelector((state) => getSubscriptions(state));

  const handleInvoiceDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "invoice");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleCancelSubscription = (product: any) => {
    setCancelSubscriptionProduct(product);
    setOpenCancelSubscription(true);
  };

  const handleUpgradeNow = (product: any, type: ProductType) => {
    if (type === ProductType.GENERAL_ACCESS) {
      setOpenSubscribeModal(true);
      setChangeGeneralAccessSubscription(true);
      setUpgradSubscriptionProduct(product);
    } else {
      setUpgradSubscriptionProduct(product);
      setOpenPlanUpgradeModal(true);
    }
    setOpenManageSubscription(false)
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };
  const handleInvoicesNextPage = () => {
    dispatch(fetchInvoices(invoiceFilter));
  };

  const handleBuySubscription = (type: any) => {
    if (ProductType.GENERAL_ACCESS === type) {
      setOpenSubscribeModal(true);
    } else if(ProductType.VA_ACCESS === type){
      setOpenVaPlanModal(true)
    }
    else {
      setOpenPlanUpgradeModal(true);
    }
    setOpenManageSubscription(false);
  };

  useEffect(() => {
    dispatch(fetchCardList({}));
    dispatch(fetchInvoices(invoiceFilter));
  }, []);

  useEffect(() => {
    if (!invoices?.invoices) {
      return;
    }
    const data = invoices?.invoices?.map((invoice: any) => ({
      id: invoice?.id,
      invoice: (
        <div>
          {invoice?.productName && (
            <>
              {invoice.productName.toUpperCase()}
              {invoice.subsProductType !== ProductType.GENERAL_ACCESS &&
                invoice.interval !== ProductPriceIntervalType.ONETIME &&
                ` (${
                  invoice.interval === ProductPriceIntervalType.MONTH
                    ? "Monthly"
                    : "Annually"
                })`}
            </>
          )}
          &nbsp;
          {invoice?.subsProductType === ProductType.CREDIT_ACCESS &&
          invoice?.amount === 0 ? (
            <span className="badge  scheduled">Paused trial plan added</span>
          ) : (
            ""
          )}
        </div>
      ),
      amount: `${(
        invoice?.currency ?? ""
      ).toUpperCase()} $${formartNumberAsLocalString(+(invoice?.amount ?? 0), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      createdAt: invoice?.createdAt
        ? dayjs(invoice?.createdAt).format(`MMM DD, YYYY`)
        : "",
      status: {
        label: invoice?.status
          ? invoice?.status.charAt(0).toUpperCase() + invoice?.status.slice(1)
          : "",
        toolTipText:
          invoice?.status === "refund"
            ? invoice?.amount_refunded === invoice?.amount
              ? `Refunded $${invoice?.amount}`
              : `Refunded $${invoice?.amount_refunded} out of $${invoice?.amount} `
            : "",
      },
      downloadInvoice: (
        <span
          onClick={() => {
            window.open(invoice?.hostedInvoiceUrl);
          }}
        >
          <Cloud />
        </span>
      ),
    }));
    setTableData(data);
    setInvoiceFilter((prev) => ({
      ...prev,
      last_Index: invoices?.startAfterId ?? "",
    }));
  }, [invoices]);


  return (
    <>
      <div className={`${styles.billing}`}>
        <div
          className={`${styles.billing__header} dflex alignCenter justifySpaceBetween`}
        >
          <div>
            <h3>Payment Methods</h3>
            <p>Update your billing details and view payment history.</p>
          </div>
          <Tooltip
            title={
              profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
                ? ROLE_PERMISSION_STATUS.NoAccess
                : ""}
          >
            <div>
              {profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER? (
                <Button
                className="outline"
                label={"Manage my subscription"}
                disabled={profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER}
                />
              ) :
          sunscriptions?.generalSubData?.lastPaymentStatus !=
          "card_declined" ? (
            sunscriptions?.generalSubData ? (
              <Button
                className="outline"
                action={() => {
                  setOpenManageSubscription(true);
                  dispatch(fetchSubscriptions({}));
                }}
                label={"Manage my subscription"}
              ></Button>
            ) : (
              <Button
                prefix={<Zap />}
                className="button primary md "
                action={() => {
                  setOpenSubscribeModal(true);
                }}
                label={"Upgrade Now"}
              ></Button>
            )
          ) : (
            <Button
              className="outline"
              action={() => {
                setOpenManageSubscription(true);
                dispatch(fetchSubscriptions({}));
              }}
              label={"Manage my subscription"}
            ></Button>
          )}

 </div>
 </Tooltip>
        </div>
        <div className={`dflex  ${styles.billing__cards}`}>
          <div className={` ${styles.cardsLeft}`}>
            <h4>Card details</h4>
            <p>Select default payment method.</p>
          </div>
          <div className={` ${styles.cardsRight}`}>
            <CardList
              displaySuccessMessage={displaySuccessMessage}
              displayErrorMessage={displayErrorMessage}
            ></CardList>
            <div className={` ${styles.addCards}`} data-testid="addNewPayment">
              <Tooltip
                title={
                  profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
                    ? ROLE_PERMISSION_STATUS.NoAccess
                    : ""
                }
              >
                <div>
                  {profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? (
                    <span className="inlineFlex alignCenter" role="disabled">
                      <Plus />
                      Add new payment method
                    </span>
                  ) : (
                    <span
                      className="inlineFlex alignCenter"
                      role="link"
                      onClick={() => {
                        setOpenAddPaymentModal(true);
                      }}
                    >
                      <Plus />
                      Add new payment method
                    </span>
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className={`dflex alignCenter justifySpaceBetween ${styles.billing__header} ${styles.billingHistory}`}
        >
          <div>
            <h3>Billing history</h3>
            <p>Access all your previous receipts.</p>
          </div>
          <ExportToCsv
            label={"Download all"}
            data={tableData || []}
            headers={INVOICE_CSV_HEADER}
            filename="Invoice.csv"
            handleChange={() => {}}
          ></ExportToCsv>
        </div>
        <div className={` ${styles.billing__tableMain}`}>
          {invoicesIsLoading ? (
            <Loader></Loader>
          ) : (
            <InvoiceTable
              tableData={tableData}
              hasMore={invoices?.hasMore}
              handleNextPage={handleInvoicesNextPage}
            ></InvoiceTable>
          )}
        </div>
      </div>
      <ManageSubscription
        handleClose={() => {
          setOpenManageSubscription(false);
        }}
        open={openManageSubscription}
        onCancelSubscription={handleCancelSubscription}
        onUpgradeNow={handleUpgradeNow}
        onBuySubscription={handleBuySubscription}
      ></ManageSubscription>
      <CancelSubscription
        handleClose={() => {
          setOpenCancelSubscription(false);
          setCancelSubscriptionProduct(null);
        }}
        open={openCancelSubscription}
        subscription={cancelSubscriptionProduct}
        displayErrorMessage={displayErrorMessage}
        displaySuccessMessage={displaySuccessMessage}
        closeManageSubscription={() => setOpenManageSubscription(false)}
      ></CancelSubscription>
      <AddPaymentModal
        handleClose={() => {
          setOpenAddPaymentModal(false);
        }}
        open={openAddPaymentModal}
      ></AddPaymentModal>
      <UpgradeModal
        handleClose={() => {
          setOpenPlanUpgradeModal(false);
          setUpgradSubscriptionProduct(null);
        }}
        open={openPlanUpgradeModal}
        product={upgradSubscriptionProduct}
        parentType={UpgradeModalParentType.Billing}
      ></UpgradeModal>

   { openVaPlanModal && <VaPlanModal
        handleClose={() => {
          setOpenVaPlanModal(false);
          setUpgradSubscriptionProduct(null);
        }}
        open={openVaPlanModal}
        product={upgradSubscriptionProduct}
      ></VaPlanModal>}

      {openSubscribeModal && (
        <SubscribeModal
          open={openSubscribeModal}
          handleClose={() => {
            setOpenSubscribeModal(false);
            setChangeGeneralAccessSubscription(false);
            setUpgradSubscriptionProduct(null);
          }}
          product={upgradSubscriptionProduct}
          changeSubscription={changeGeneralAccessSubscription}
          displayErrorMessage={displayErrorMessage}
          displaySuccessMessage={displaySuccessMessage}
        ></SubscribeModal>
      )}
    </>
  );
};
