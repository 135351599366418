import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "../../atoms/Button";
import CustomModal from "../../components/customModal/CustomModal";
import { updateProfileAction } from "../../components/profileMenu/sagaActions";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { useHelpModalContext } from "../../context/HelpModalContext";
import Map, { MapViewMode } from "../../molecules/map";
import { setCreateLeads } from "../../organisms/addLeads/reducer";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { pageNameFn } from "../../organisms/auth/enum";
import PropertySearchFilter from "../../organisms/propertySearchFilter/PropertySearchFilter";
import {
  fetchSavedSearchFilters,
  propertyCount,
} from "../../organisms/propertySearchFilter/PropertyService";
import {
  DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE,
  DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
  DEFAULT_FORM_VALUE,
  SAVED_FILTER_UPDATION_KEYS,
  SEARCH_ON_BLUR_INPUTS_LIST,
} from "../../organisms/propertySearchFilter/constants";
import {
  resetPropertySearchState,
  setAddressPredictions,
  setSearchedProperties,
  setSearchTerm,
} from "../../organisms/propertySearchFilter/reducer";
import { searchProperty } from "../../organisms/propertySearchFilter/sagaActions";
import {
  getPropertiesList,
  getSearchPropertiesRequestId,
  getSearchPropertiesResultCount,
} from "../../organisms/propertySearchFilter/selectors";
import WelcomeModal from "../../organisms/welcomeModal/WelcomeModal";
import { ModalCloseReason } from "../../organisms/welcomeModal/interface";
import {
  fbc,
  fbp,
  fetchIp,
  formartNumberAsLocalString,
  hashNumber,
} from "../../utils/utils";
import PropertyDetails from "../property/PropertyDetails";
import { PropertyDetailsParentIdType } from "../property/property.enum";
import styles from "./PropertySearch.module.scss";
import PropertySearchRightPanel from "./PropertySearchRightPanel";
import { MapShapesPayload, MapShapesPayloadRaw } from "./interface";
import { createMapShapePayload, createSearchPayload } from "./utils";
import { advancedFilterFormSchema } from "./validation";
import { useDbounce } from "../../hooks/debounce.hook";

const PropertySearch = () => {
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(advancedFilterFormSchema),
    defaultValues: DEFAULT_FORM_VALUE,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const { modalContent, setModalContent } = useHelpModalContext();
  const [mapRightActive, setMapRightActive] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [disableSearch, setDisableSearch] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hoveredProperty, setHoveredProperty] = useState<any>(null);
  const [appliedAdvancedFiltersFlags, setAppliedAdvancedFiltersFlags] =
    useState<any>(DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE);
  const requestId = useSelector((state) => getSearchPropertiesRequestId(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [openAllFilter, setOpenAllFilter] = useState(false);
  const [init, setInit] = useState(false);
  const [advancedFilterCount, setAdvancedFilterCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [propertiesCount, setPropertiesCount] = useState(-1);
  const [savedFilters, setSavedFilters] = useState([]);
  const [updateSavedFilter, setUpdateSavedFilter] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const getSavedSearchFilters = async () => {
    try {
      const res = await fetchSavedSearchFilters();
      setSavedFilters(res?.data?.data || []);
    } catch (error) {}
  };

  const page: any = pageNameFn(location?.pathname.split("/")[1]);

  const handleClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: ModalCloseReason
  ) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      dispatch(
        updateProfileAction({
          [page?.pagename]: true,
          organisationName: profileInfo?.organisation?.organisationName,
        })
      );
    }
    setOpenWelcome(false);
  };

  const propertiesList = useSelector((state) => getPropertiesList(state));
  const [propertiesInView, setPropertiesInView] = useState<any[]>([]);
  const searchPropertiesResultCount = useSelector((state) =>
    getSearchPropertiesResultCount(state)
  );

  const handleNextPage = () => {
    setPageNumber((prev) => {
      if (prev === 1) {
        return 4;
      }
      return prev + 1;
    });
  };

  const handlePropertyClick = (property: any) => {
    setSelectedProperty(property);
    handleMarkerClickFn(property);
  };

  const handlePropertyModal = () => {
    setShowPropertyModal(false);
    setPropertyId("");
  };
  const handleMapApplyButtonClick = useCallback(
    (payload: MapShapesPayloadRaw) => {
      if (payload.polylines.length) {
        const newMapShapesPayload: MapShapesPayload =
          createMapShapePayload(payload);
        methods.setValue("mapShapesPayload", newMapShapesPayload);
        dispatchSearchProperty(
          { ...methods.watch(), mapShapesPayload: newMapShapesPayload },
          1,
          false
        );
      } else {
        methods.setValue("mapShapesPayload", null);
      }
      dispatch(setSearchTerm(" "));
    },
    [dispatch]
  );

  const handleMapRemoveBoundariesButtonClick = useCallback(() => {
    dispatch(setSearchedProperties({ data: { results_count: -1 } }));
    methods.setValue("mapShapesPayload", null);
    setDisableSearch(false);
    dispatch(setSearchTerm(""));
    setPageNumber(1);
    resetAdvancedFilter(
      DEFAULT_APPLIED_ADVANCED_FILTER_FORM_STATE,
      DEFAULT_FORM_VALUE
    );
  }, [dispatch]);

  const handleMapDrawButtonClick = useCallback(() => {
    methods.setValue("searchTerm", "");
  }, [methods]);

  const handleMapCancelButtonClick = useCallback(() => {
    setDisableSearch(false);
  }, []);

  const toggleMarker = useCallback(
    (id: number | string) => {
      const property = propertiesList.find(
        (property: any) => property?.propertyId === id
      );
      setSelectedProperty(property ?? null);
    },
    [propertiesList]
  );

  const handleMarkerClickFn = async (property: any) => {
    dispatch(setCreateLeads({}));
    setPropertyId(property?.propertyId);
    // if (property?.propertyId) {
    //   let link = document.createElement("a");
    //   document.body.appendChild(link);
    //   link.href = `/properties/${property?.propertyId}`;
    //   link.target = "_blank";
    //   link.click();
    //   document.body.removeChild(link);
    // }
  };

  const markers = useMemo(() => {
    return (window.innerWidth >= 1025 ? propertiesInView : propertiesList)?.map(
      (property: any) => {
        const features: {
          [key: string]: any;
        } = {
          bed: property?.buildingInfo?.bedroomCount
            ? property?.buildingInfo?.bedroomCount
            : "-",
          bath: property?.buildingInfo?.bedroomCount
            ? property?.buildingInfo?.bedroomCount
            : "-",
          sqft: property?.buildingInfo?.livingAreaSquareFeet
            ? formartNumberAsLocalString(
                property?.buildingInfo?.livingAreaSquareFeet
              )
            : "-",
          "lot sqft": property?.buildingInfo?.totalBuildingAreaSquareFeet
            ? formartNumberAsLocalString(
                property?.buildingInfo?.totalBuildingAreaSquareFeet
              )
            : "-",
          "year built": property?.buildingInfo?.effectiveYearBuilt
            ? property?.buildingInfo?.effectiveYearBuilt
            : "-",
        };

        const propertyfeaturesAsString = Object.keys(features)
          .map((key) => (features[key] ? `${features[key]} ${key}` : null))
          .filter(Boolean)
          .join(" | ");

        return {
          id: property?.propertyId ?? 0,
          coordinate: {
            lat: property?.latitude ?? 0,
            lng: property?.longitude ?? 0,
            altitude: 20,
          } as google.maps.LatLngAltitudeLiteral,
          component: (
            <></>
            // <MarkerPopup
            //   onClose={() => {
            //     setSelectedProperty(null);
            //   }}
            //   headerText={`${
            //     property?.address?.street
            //       ? property?.address?.street + ", "
            //       : ""
            //   }${
            //     property?.address?.city ? property?.address?.city + ", " : ""
            //   }${property?.address?.state ?? ""} ${
            //     property?.address?.zip ?? ""
            //   }`}
            //   infoText={propertyfeaturesAsString}
            //   handleMarkerClick={() => handleMarkerClickFn(property)}
            // ></MarkerPopup>
          ),
          hovered: property?.propertyId === hoveredProperty,
          streetno: property?.address,
        };
      }
    );
  }, [propertiesList, propertiesInView]);

  const handleMouseHover = (property: any) => {
    setHoveredProperty(property?.propertyId);
  };

  const handleMouseLeave = () => {
    setHoveredProperty(null);
  };

  const debounce = useDbounce(500, async(data: any) => {
    try {
      const response = await propertyCount(data);
      setPropertiesCount(response?.data?.count);
    } catch (error) {
      console.error( error)
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setInit(true);
    dispatch(setAddressPredictions([]));
    getSavedSearchFilters();
    return () => {
      dispatch(resetPropertySearchState({}));
      dispatch(setSearchTerm(""));
    };
  }, []);

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Property_Search_View",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                external_id: hashNumber(
                  profileInfo?.data?.result?.organisationId
                ),
              },
            },
          ],
        };

        await FbConversionApi(payloadData);
      } catch (error) {
        console.error("Error sending payload:", error);
      }
    };
    fbConversionApiSend();
  }, [profileInfo]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: page?.title,
      subtitle: page?.subtitle,
      video: page?.video,
    });

    if (
      !profileInfo?.userWalkthrough?.[page?.pagename] ||
      profileInfo?.userWalkthrough?.[page?.pagename] === false
    ) {
      setOpenWelcome(true);
      // setBtnText("Continue");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !propertiesList?.length ||
      propertiesList.length === searchPropertiesResultCount
    ) {
      setPropertiesInView([]);
      return;
    }

    const parent = document.getElementById("scrollableDiv1");
    const child = parent?.getElementsByClassName(
      "infinite-scroll-component "
    )[0];

    const parentHeight = parent?.clientHeight;
    const childHeight = child?.clientHeight;

    if (!parentHeight || !childHeight || childHeight >= parentHeight) {
      return;
    }

    handleNextPage();
  }, [propertiesList, searchPropertiesResultCount]);

  const updateMapRightActive = () => {
    if (methods.watch("searchTerm") || methods.watch("mapShapesPayload")) {
      setMapRightActive(true);
    } else {
      setMapRightActive(false);
    }
  };

  const onApplyFilter = (data: any, flag?: any) => {
    dispatchSearchProperty(methods.watch(), 1, false);
    setOpenAllFilter(false);
  };

  const resetAdvancedFilter = (filterState: any, formValue: any) => {
    methods.reset({ ...methods.watch(), savedFilter: "", ...formValue });
    setAppliedAdvancedFiltersFlags(DEFAULT_APPLIED_ADVANCED_FILTER_FLAGS_STATE);
    // resetSearchedPropertie();
  };
  const resetSearchedPropertie = () => {
    dispatch(
      setSearchedProperties({
        data: { result: [], results_count: -1, potential_count: 0 },
      })
    );
  };

  useEffect(() => {
    if (pageNumber === 1) {
      return;
    }
    if (methods.watch("searchTerm") || methods.watch("mapShapesPayload")) {
      dispatchSearchProperty(methods.watch(), pageNumber, false);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (propertyId) {
      setShowPropertyModal(true);
    }
  }, [propertyId]);

  useEffect(() => {
    if (!showPropertyModal) {
      setPropertyId("");
    }
  }, [showPropertyModal]);

  const dispatchSearchProperty = async (
    filter: any,
    pageNumber: number = 0,
    updateCount: boolean = false
  ) => {
    if (
      (Object.keys(filter.address).length > 0 && filter.searchTerm) ||
      filter.mapShapesPayload
    ) {
      const payload = createSearchPayload(
        filter,
        pageNumber,
        setAppliedAdvancedFiltersFlags,
        setAdvancedFilterCount
      );
      if (updateCount) {
        try {
          setLoading(true);
          debounce(payload)
        } catch (error) {
          setLoading(false);
        }
      } else {
        dispatch(searchProperty(payload));
      }
    }
    updateMapRightActive();
  };

  useEffect(() => {
    if (
      methods.watch("mapShapesPayload") === null &&
      methods.watch("searchTerm").length < 1
    ) {
      setPropertiesCount(-1);
    }
  }, [methods.watch("mapShapesPayload"), methods.watch("searchTerm")]);

  useEffect(() => {
    methods.setValue("propertyCharacteristicsFilter.propertyType", []);
  }, [methods.watch("propertyCharacteristicsFilter.classifications")]);

  useEffect(() => {
    let sub: any;
    if (init) {
      sub = methods.watch((value: any, type: any) => {
        if (
          type.name !== "searchTerm" &&
          type.name !== "mapShapesPayload" &&
          type.name !== "requestId" &&
          type.name !== "marketStatus" &&
          type.name !== "savedFilter" &&
          !SEARCH_ON_BLUR_INPUTS_LIST.includes(type.name)
        ) {
          setPageNumber(1);
          dispatchSearchProperty(value, 1, true);
        }
        if (type.name === "marketStatus") {
          setPageNumber(1);
          dispatchSearchProperty(value, 1, false);
        }
        const updatingKeys = SAVED_FILTER_UPDATION_KEYS.filter((el) => {
          if (type?.name) {
            return type?.name?.includes(el);
          }
          return false;
        });
        if (type.name === "savedFilter") {
          setUpdateSavedFilter(false);
        } else if (value.savedFilter && updatingKeys?.length > 0) {
          setUpdateSavedFilter(true);
        }
      });
    }
    return () => sub?.unsubscribe();
  }, [methods.watch, init]);

  const [foreclosureValue, setForeclosureValue] = useState(
    methods.watch("auctionsPreforeclousreFilter.exclude_foreclosure") || false
  );

  useEffect(() => {
    if (methods.watch("auctionsPreforeclousreFilter.auction")) return;
    methods.setValue("auctionsPreforeclousreFilter.auctionDate", undefined);
  }, [methods.watch("auctionsPreforeclousreFilter.auction"), methods.setValue]);

  useEffect(() => {
    if (
      methods.watch(
        "auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
      )
    ) {
      methods.setValue(
        "auctionsPreforeclousreFilter.exclude_foreclosure",
        false
      );
      setForeclosureValue(false);
    }
  }, [
    methods.watch(
      "auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
    ),
    methods.setValue,
  ]);

  useEffect(() => {
    setForeclosureValue(
      methods.watch("auctionsPreforeclousreFilter.exclude_foreclosure") || false
    );
  }, [methods.watch("auctionsPreforeclousreFilter.exclude_foreclosure")]);

  useEffect(() => {
    if (foreclosureValue) {
      methods.setValue(
        "auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange",
        null
      );
    }
  }, [foreclosureValue, methods.setValue]);

  useEffect(() => {
    if (requestId) {
      methods.setValue("requestId", requestId);
    }
  }, [requestId]);

  return (
      <div className={` ${styles.propertySearch}`}>
        <div className={`dflex ${styles.propertySearch__mapSection} `}>
          <div
            className={`${styles.propertySearch__map} ${
              mapRightActive ? styles.rightActive : ""
            }`}
          >
            <FormProvider {...methods}>
              <PropertySearchFilter
                disableSearch={disableSearch}
                setAppliedAdvancedFiltersFlags={setAppliedAdvancedFiltersFlags}
                dispatchSearchProperty={dispatchSearchProperty}
                resetAdvancedFilter={resetAdvancedFilter}
                setPropertiesCount={setPropertiesCount}
              ></PropertySearchFilter>
            </FormProvider>

            <Map
              mapId="property-search-map"
              height="calc(100vh - 136px)"
              selectedMarkers={
                selectedProperty?.propertyId ? [selectedProperty?.id] : []
              }
              toggleMarker={toggleMarker}
              markers={markers}
              coordinate={{
                lat: selectedProperty
                  ? selectedProperty?.latitude
                  : propertiesList?.[0]?.latitude ?? null,
                lng: selectedProperty
                  ? selectedProperty?.longitude
                  : propertiesList?.[0]?.longitude ?? null,
              }}
              initialViewMode={MapViewMode.ROADMAP}
              allowToDrawShapes={true}
              onApplyButtonClick={handleMapApplyButtonClick}
              onRemoveBoundariesButtonClick={
                handleMapRemoveBoundariesButtonClick
              }
              onDrawButtonClick={handleMapDrawButtonClick}
              onCancelButtonClick={handleMapCancelButtonClick}
              hovered={hoveredProperty}
            />
          </div>

          {openWelcome && (
            <WelcomeModal
              title={modalContent.title}
              subtitle={modalContent.subtitle}
              button={
                <Button
                  action={handleClose}
                  className="primary xl full"
                  label={"Got it"}
                />
              }
              video={modalContent.video}
              open={openWelcome}
              handleClose={handleClose}
              termsOfUseFlag={true}
            />
          )}

          {mapRightActive && (
            <FormProvider {...methods}>
              <PropertySearchRightPanel
                propertiesInView={propertiesInView}
                setPropertiesInView={setPropertiesInView}
                pageNumber={pageNumber}
                setOpenAllFilter={setOpenAllFilter}
                openAllFilter={openAllFilter}
                savedFilters={savedFilters}
                getSavedSearchFilters={getSavedSearchFilters}
                updateSavedFilter={updateSavedFilter}
                setUpdateSavedFilter={setUpdateSavedFilter}
                handleNextPage={handleNextPage}
                selectedProperty={selectedProperty}
                setAppliedAdvancedFiltersFlags={setAppliedAdvancedFiltersFlags}
                setAdvancedFilterCount={setAdvancedFilterCount}
                loading={loading}
                handlePropertyClick={handlePropertyClick}
                handleMouseHover={handleMouseHover}
                handleMouseLeave={handleMouseLeave}
                onApplyFilter={onApplyFilter}
                appliedAdvancedFiltersFlags={appliedAdvancedFiltersFlags}
                resetAdvancedFilter={resetAdvancedFilter}
                propertiesCount={propertiesCount}
                dispatchSearchProperty={dispatchSearchProperty}
                setPageNumber={setPageNumber}
              ></PropertySearchRightPanel>
            </FormProvider>
          )}

          {propertyId && (
            <CustomModal
              open={showPropertyModal}
              handleClose={handlePropertyModal}
            >
              <PropertyDetails
                selectedPropertyId={propertyId}
                displayText={"Property Search"}
                handleModalClose={handlePropertyModal}
                type={PropertyDetailsParentIdType.REFERENCE_ID}
              />
            </CustomModal>
          )}
        </div>
      </div>
  );
};
export default PropertySearch;
