import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import SearchReflation from "../../../assets/icons/searchReflation";
import DateRangePickerCalendar from "../../../atoms/DateRangePickerCalendar";
import DropDown from "../../../atoms/DropDown";
import MultiSelectDropDown from "../../../atoms/MultiSelectDropDown";
import {
  AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS,
  AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS,
} from "../constants";
import { useEffect } from "react";

const AuctionsPreForeclosure = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    getValues
  }: any = useFormContext();

  const methods = useFormContext();

  useEffect(() => {
    const updateQuickFilters = (filter:any, condition:any) => {
      if (condition) {
        if (!quickFilters.includes(filter)) {
          quickFilters.push(filter);
        }
      } else {
        quickFilters = quickFilters.filter((item:any) => item !== filter);
      }
    };

    let quickFilters = getValues("quickFilters");
    const auctionsPreforeclousreStatusFilters = methods.watch("auctionsPreforeclousreFilter.auctionsPreforeclousreStatus");
    updateQuickFilters("NOTICE_OF_DEFAULT", auctionsPreforeclousreStatusFilters?.includes("NOTICE_OF_DEFAULT"));
    updateQuickFilters("NOTICE_OF_LIS_PENDENS", auctionsPreforeclousreStatusFilters?.includes("NOTICE_OF_LIS_PENDENS"));
    updateQuickFilters("NOTICE_OF_SALE", auctionsPreforeclousreStatusFilters?.includes("NOTICE_OF_SALE"));

    const auctionFilters = methods.watch("auctionsPreforeclousreFilter.auction");
    updateQuickFilters("ACTIVE_AUCTION", auctionFilters === true);
    methods.setValue("quickFilters", quickFilters);
  }, [
    methods.watch("auctionsPreforeclousreFilter.auctionsPreforeclousreStatus"),
    methods.watch("auctionsPreforeclousreFilter.auction")
  ]);
  
  return (
      <div className={`colRow`}>
        <div className={`colRow__col`}>
          <p>Foreclosure Status</p>
          <MultiSelectDropDown
            options={AUCTIONS_PREFORECLOSURE_STATUS_OPTIONS}
            label="Any"
            name={"auctionsPreforeclousreFilter.auctionsPreforeclousreStatus"}
            control={control}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></MultiSelectDropDown>
        </div>
        <div className={`colRow__col select-bold`}>
          <p>Recording Date</p>

          <DropDown
            options={AUCTIONS_PREFORECLOSURE_SEARCH_RANGE_OPTIONS}
            label=""
            control={control}
            name="auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
            errors={
              errors?.["auctionsPreforeclousreFilter"]?.[
                "auctionsPreforeclousreSearchRange"
              ]
            }
            placeholder="Any"
            clearOnBlur={true}
          ></DropDown>

          {/* <DateRangePickerCalendar
            placeHolder="Select dates"
            control={control}
            name="auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
            errors={
              errors?.["auctionsPreforeclousreFilter"]?.[
                "auctionsPreforeclousreSearchRange"
              ]
            }
          /> */}
        </div>
        <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="auctionsPreforeclousreFilter.auction"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={!!field.value || false}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Active Auction</h5>
                  <p>
                    Used to include properties which are scheduled to go to
                    auction.
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <div className={`colRow__col`}>
          <Tooltip
            title={
              !watch("auctionsPreforeclousreFilter.auction")
                ? "Please check auction checkbox first"
                : ""
            }
          >
            <div>
              <p>Auction Date</p>
              {/* <label> */}
              <DateRangePickerCalendar
                placeHolder="Select dates"
                control={control}
                disabled={!watch("auctionsPreforeclousreFilter.auction")}
                name="auctionsPreforeclousreFilter.auctionDate"
                errors={
                  errors?.["auctionsPreforeclousreFilter"]?.["auctionDate"]
                }
              />
              {/* <CustomDateRangePicker
                  placeHolder="Select dates"
                  control={control}
                  disabled={!auctionValue}
                  name="auctionsPreforeclousreFilter.auctionDate"
                  errors={
                    errors?.["auctionsPreforeclousreFilter"]?.["auctionDate"]
                  }
                ></CustomDateRangePicker> */}
              {/* </label> */}
            </div>
          </Tooltip>
        </div>
        {/* <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <Tooltip
              title={
                watch(
                  "auctionsPreforeclousreFilter.auctionsPreforeclousreSearchRange"
                )
                  ? "Recording date will get reset"
                  : ""
              }
            >
              <FormControlLabel
                control={
                  <Controller
                    name="auctionsPreforeclousreFilter.exclude_foreclosure"
                    control={control}
                    render={({ field, ...props }) => {
                      return (
                        <>
                          <Checkbox
                            checked={field.value || false}
                            onChange={field.onChange}
                          />
                        </>
                      );
                    }}
                  />
                }
                label={
                  <div className="switchLabel__label">
                    <h5>Exclude Foreclosures</h5>
                    <p>
                      Don’t include properties with the foreclosure attribute.
                    </p>
                  </div>
                }
              />
            </Tooltip>
          </div>
        </div> */}
        {/* <div className={`colRow__col`}>
          <div className={`switchLabel flex alignCenter`}>
            <FormControlLabel
              control={
                <Controller
                  name="auctionsPreforeclousreFilter.reo"
                  control={control}
                  render={({ field, ...props }) => {
                    return (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </>
                    );
                  }}
                />
              }
              label={
                <div className="switchLabel__label">
                  <h5>Bank Owned (REO)</h5>
                  <p>Used to find properties which are owned by the bank.</p>
                </div>
              }
            />
          </div>
        </div> */}
      </div>
  );
};
export default AuctionsPreForeclosure;
