import { initialState } from "./reducer";

const getStateData = (state: any) => state["myListsTable"] || initialState;

export const getMylistData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.myLists;
};

export const getListIsLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listIsLoading;
};

export const getMyListTotal = (state: any) => {
  const reducer = getStateData(state);
  return reducer.total;
};

export const getLeadsListDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadListDetail;
};

export const getListDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listDetail;
};

export const getListDetailLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listdetailLoading;
};

export const getcallAttemptsCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.callAttemptsCount;
};