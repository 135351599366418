import Button from "../../atoms/Button";
import HeroSection from "../../organisms/heroSection/HeroSection";
import styles from "./CampaignDetail.module.scss";
import Chart from "../../molecules/chart/commonChart";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowRight from "../../assets/icons/arrowRight";
import Call from "../../assets/icons/call";
import ClockForward from "../../assets/icons/clockForward";
import Edit from "../../assets/icons/edit";
import Filter from "../../assets/icons/fiter";
import SearchReflation from "../../assets/icons/searchReflation";
import SquareClose from "../../assets/icons/squareClose";
import DropDown from "../../atoms/DropDown";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";
import SearchBar from "../../atoms/SearchBar";
import { useDbounce } from "../../hooks/debounce.hook";
import { SortDirection } from "../../molecules/table/enum";
import AnalyticCards from "../../organisms/analyticCards/AnalyticCards";
import FilterDropdown from "../../organisms/filterDropdown/FilterDropdown";
import {
  fbc,
  fbp,
  fetchIp,
  firstName,
  formartNumberAsLocalString,
  getDateDifferenceInWords,
  hashNumber,
  hashPhoneNumber,
  hashString,
  toCamelCase,
} from "../../utils/utils";
import CancelCampaign from "./cancelCampaign";
import EditCampaign from "./editCampaign";
import {
  activityAction,
  campaignDataAction,
  fetchPropertyCountByCampaign,
  getAttemptIdAction,
  graphAction,
  leadsAction,
  topMatrixAction,
  topSectionAction,
} from "./sagaAction";
import {
  activityLoading,
  campaignDataResponse,
  getActivityData,
  getActivityTotalList,
  getAttemptDetails,
  getGraphData,
  getIsAttemptSelected,
  getLeadsData,
  getPropertyCountByCampaign,
  getTopMatrixData,
  getTopSectionData,
  getTotalList,
  leadsLoading,
} from "./selector";
import AddAnotherAttempt from "../../organisms/campaignDetails/AddAnotherAttempt";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import UpgradeModal from "../../organisms/upgradeModal/UpgradeModal";
import { getSubscriptions } from "../../organisms/billing/selectors";
import { UpgradeModalParentType } from "../../organisms/upgradeModal/enum";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import {
  resetCampaignDetailState,
  setLeadsLoading,
  setTopSectionData,
} from "./reducer";
import Circle from "../../assets/icons/circle";
import { calculateProgress } from "./utils";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomTable from "../../molecules/table/CustomTable";
import { Leads_COLUMN, LeadsDetail_columns, ZONE_TIME } from "./constants";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import { fetchListData, fetchleadsExportList } from "../../organisms/campaignDetails/campaignDetailsService";
import ArrowUp from "../../assets/icons/arrowUp";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import { FbConversionApi } from "../../organisms/auth/authServices";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import CallBackNo from "../../organisms/createCampaign/CallBackNo";
import { Tab, Tabs, Tooltip } from "@mui/material";
import React from "react";
import HungUp from "./leadStatusTabs/hungUp/HungUp";
import VoiceMail from "./leadStatusTabs/VoiceMail/VoiceMail";
import Dead from "./leadStatusTabs/dead/Dead";
import WrongNumber from "./leadStatusTabs/WrongNumber/WrongNumber";
import Dnc from "./leadStatusTabs/dnc/Dnc";
import Failed from "./leadStatusTabs/failed/Failed";
import { PermissionListEnum } from "../../organisms/auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import { getSelectedLeadId } from "../../organisms/leadsTable/selectors";
import CustomModal from "../../components/customModal/CustomModal";
import PropertyDetails from "../property/PropertyDetails";
import { PropertyDetailsParentIdType } from "../property/property.enum";
import { setSelectedLeadId } from "../../organisms/leadsTable/reducer";
import { fetchCampaignBotVoiceList } from "../../organisms/createCampaign/sagaActions";

type LeadFilterType = "" | "sms" | "call";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(270deg, #18CEFF -0.12%, #603FFE 100%)"
        : "#308fe8",
  },
}));

const attemptIdMap: any[] = [];

const CampaignDetail = () => {
  const { control, resetField, getValues, setValue,reset } = useForm();
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [searchLeadsText, setSearchLeadsText] = useState<string>("");
  const [openAttemptFilter, setOpenAttemptFilter] = useState<boolean>(false);
  const [anchorAttempt, setAnchorAttempt] = useState<null | HTMLElement>(null);
  const [attemptOptions, setAttemptOptions] = useState<
    { value: number; label: any }[]
  >([]);
  const [attemptNoState, setAttemptNoState] = useState<string>("");
  const [selectedAttempt, setSelectedAttempt] = useState<string>("");
  const [focused, setFocused] = useState(false);
  const [campaignProgress, setCampaignProgress] = useState(0);
  const [openAddAnotherAttemptModal, setOpenAddAnotherAttemptModal] =
    useState(false);
  const [openPlanUpgradeModal, setOpenPlanUpgradeModal] = useState(false);
  const [upgradSubscriptionProduct, setUpgradSubscriptionProduct] =
    useState(null);
  const [filterFlag, setFilterFlag] = useState(false);
  const[listIdArray, setListIdArray] = useState([]);
 
  const [listItems, setListItems] = useState([]);
  const [filter, setFilter] = useState<{
    sortDirection: string;
    sortKey: string;
    leadFilterBy: LeadFilterType;
    index: number;
    size: number;
  }>({
    sortDirection: SortDirection.DESC,
    sortKey: "createdAt",
    leadFilterBy: "",
    index: 1,
    size: 20,
  });

  const response = useSelector((state: any) => campaignDataResponse(state));
  const leadsData = useSelector((state: any) => getLeadsData(state));
  const total = useSelector((state: any) => getTotalList(state));
  const activityData = useSelector((state: any) => getActivityData(state));
  const ActivityTotal = useSelector((state: any) =>
    getActivityTotalList(state)
  );
  const isActivityLoading = useSelector((state: any) => activityLoading(state));
  const isLeadsLoading = useSelector((state: any) => leadsLoading(state));
  const topMatrixData = useSelector((state: any) => getTopMatrixData(state));
  const sectionData = useSelector((state: any) => getTopSectionData(state));
  const graphData = useSelector((state) => getGraphData(state));
  const isAttemptSelected = useSelector((state) => getIsAttemptSelected(state));
  const attemptsData = useSelector((state) => getAttemptDetails(state));
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const profileData = useSelector((state) => getProfileInfo(state));
  const propertyCountByCampaign = useSelector((state) =>
    getPropertyCountByCampaign(state)
  );

  const existingData = graphData?.data?.result || [];
  const currentDate = new Date();
  const [exportListLoading, setExportListLoading] = useState(false);
  const selectedLeadId = useSelector((state) => getSelectedLeadId(state));
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [openCallBackNoModal, setOpenCallBackNoModal] = useState<boolean>(false);
  const newData = existingData.some((point: any) => point.timeStamp === "00:00")
    ? existingData
    : [{ timeStamp: "00:00", count: 0 }, ...existingData];

  const xValues =
    newData?.map((point: any) => {
      if (point.timeStamp === "00:00") {
        return "Start";
      }
      const [hours, minutes] = point.timeStamp.split(":")?.map(Number);
      if (hours === 0 && minutes > 0) {
        return `${minutes} m`;
      } else if (hours > 0 && minutes === 0) {
        return `${hours} h`;
      } else if (hours > 0 && minutes > 0) {
        return `${hours} h ${minutes} m`;
      } else {
        return "Invalid time format";
      }
    }) || [];

  const options = {
    colors: ["#005DA4"],
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "18px",
        borderRadius: 4,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      categories: xValues,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "Calls Sent",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  };

  const yValues =
    graphData?.data?.result?.map((point: any) => {
      const count = point?.count;
      return count;
    }) || [];

  const cards = [
    {
      title: "Leads",
      statusKey: "todayLeadCount",
      statusToday: true,
      // value: response?.data?.result[0]?.leadCount,
      key: "totalLeadCount",
    },
    {
      title: "Total Calls",
      value: response?.data?.result[0]?.callCount,
      statusToday: true,
      statusKey: "todayCallCount",
      key: "totalCallCount",
    },
    {
      title: "Conversations",
      value: response?.data?.result[0]?.conversionCount,
      statusToday: true,
      statusKey: "todayConversion",
      key: "totalConversion",
    },
    {
      title: "Voicemail",
      // value: response?.data?.result[0]?.noAnswerCalls,
      key: "voiceMail",
    },
  ];

  const series = [
    {
      name: "Calls Sent",
      data: yValues,
    },
  ];

  const debounce = useDbounce(500, (data: any) => {
    const { filter, ...rest } = data;
    const payload = {
      ...rest,
      selectedSortDirection: filter?.sortDirection,
      selectedSortKey: filter?.sortKey,
      leadFilterBy: filter?.leadFilterBy,
      size: filter?.size,
      index: filter?.index,
    };
    dispatch(setLeadsLoading(true));
    dispatch(leadsAction(payload));
  });

  const handlePropertyModal = () => {
    setShowPropertyModal(false);
    dispatch(setSelectedLeadId(""));
  };

  const handleNextPage = () => {
   setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
    const payload = {
      searchLeadsText,
      id,
      selectedAttempt,
      selectedSortDirection: filter.sortDirection,
      selectedSortKey: "createdAt",
      leadFilterBy: filter.leadFilterBy,
      size: filter.size,
      index: filter.index+1,
    };
    dispatch(leadsAction(payload));
  };

  const handleLeadsSearch = (e: any) => {
    let value = e.target.value;
    if (e.target.value?.length > 0 && e.target.value.trim()?.length === 0) {
      value = "";
    }
    if (searchLeadsText !== value) {
      setSearchLeadsText(value);
    }
  };

  const downloadCSV = () => {
    const csvData = leadsData.map((lead: any) => ({
      created_at: lead?.createdAt,
      name_first: lead?.name_first,
      name_last: lead?.name_last,
      street: lead?.street,
      city: lead?.city,
      state: lead?.state,
      phone_number: lead?.phoneNumber,
      phone_attempt_summary: lead?.summary,
    }));

    const csvRows = [];
    const headers = Object.keys(csvData[0]);
    csvRows.push(headers.join(","));
    for (const row of csvData) {
      const values = headers?.map((header) => {
        const escaped = ("" + row[header])?.replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const blob = new Blob([csvRows.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leads.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFilterBtn = (event: any) => {
    setAnchorAttempt(event.currentTarget);
    setOpenAttemptFilter(true);
    setFocused(true);
    setValue("attemptList", attemptNoState);
  };

  const handleAttemptClose = () => {
    setAnchorAttempt(null);
    setFocused(false);
  };

  const handleApplyBtn = useCallback(() => {
    setListIdArray(getValues()?.lists)
    let newSelectedAttempt = selectedAttempt;
    if (getValues("attemptList")?.length === 0) {
      resetField("attemptList");
      setAttemptNoState("");
      newSelectedAttempt = "";
      setFilterFlag(false);
    }
    const payload = {
      id,
      selectedAttempt,
      listArray: getValues()?.lists
    };
 
    setSelectedAttempt(newSelectedAttempt);
    dispatch(leadsAction(payload));
    dispatch(topSectionAction(payload));
    dispatch(topMatrixAction(payload));
    dispatch(activityAction({ ...payload, index: 0, size: 20 }));
    dispatch(graphAction(payload));
    debounce({
      searchLeadsText,
      id,
      selectedAttempt: newSelectedAttempt,
      filter,
      listArray : getValues()?.lists
    });
    if (getValues("attemptList") || getValues("lists")) {
      setAttemptNoState(getValues("attemptList"));
      setFilterFlag(true);
    }
    handleAttemptClose();
  }, [selectedAttempt]);

  const handleChangeOnLeadsFilter = (leadFilterBy: LeadFilterType) => {
    const newFilters = { ...filter, leadFilterBy };
    setFilter(newFilters);
    dispatch(
      leadsAction({
        id: id,
        searchLeadsText,
        selectedAttempt,
        selectedSortDirection: newFilters.sortDirection,
        selectedSortKey: "createdAt",
        leadFilterBy: newFilters.leadFilterBy,
      })
    );
  };

  const [leadsdetail, setLeadsDetail] = useState([]);

  const handleRowOnClick = (id: string | number) => {
    // window.open(`/properties/${id}`);
    // window.scrollTo(0, 0);
    dispatch(setSelectedLeadId(id));
  };

  const arrowClick = (sortKey: string) => {
    const newSortDirection =
      filter.sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC;
    setFilter({
      ...filter,
      sortDirection: newSortDirection,
      sortKey: sortKey,
      leadFilterBy: filter.leadFilterBy,
    });
    dispatch(
      leadsAction({
        id: id,
        searchLeadsText,
        selectedAttempt,
        selectedSortDirection: newSortDirection,
        selectedSortKey: "createdAt",
        leadFilterBy: filter.leadFilterBy,
        index: 0,
        size: 20,
        listArray :listIdArray
      })
    );
  };

  const handleResetBtn = () => {
    resetField("attemptList");
    reset({lists:[]})
    setListIdArray([])
    setAttemptNoState("");
    setSelectedAttempt(() => "");
    setFilterFlag(false);
  };

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  const opUpgradeNow = () => {
    setOpenPlanUpgradeModal(true);
    setUpgradSubscriptionProduct(sunscriptions?.creditSubData);
  };
  const handleNextActivityPage = () => {
    const payload = {
      id,
      selectedAttempt,
      size: 20,
      index: activityData?.length,
      listArray :listIdArray
    };
    dispatch(activityAction(payload));
  };
  const handleChange = async () => {
    try {
      setExportListLoading(true);
      const result: any = await fetchleadsExportList({
        id: id,
        sortDirection: filter.sortDirection,
        sortKey: filter.sortKey,
        index: 0,
        size: 0,
      });
      setExportListLoading(false);
      return result?.data?.data.map((item: any) => ({
        ...item,
        summary: `"${item.summary}"`,
      }));
    } catch (error) {
      setExportListLoading(false);
    }
  };

  useEffect(() => {
    if (
      searchLeadsText?.length > 0 &&
      searchLeadsText?.length !== searchLeadsText?.trim()?.length
    ) {
      return;
    }
    debounce({ searchLeadsText, id, selectedAttempt, filter });
  }, [searchLeadsText]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPropertyCountByCampaign({ id: id }));
    dispatch(getAttemptIdAction({ id: id }));
    dispatch(topSectionAction({ id: id }));
    dispatch(fetchCampaignBotVoiceList({}));
    return () => {
      dispatch(resetCampaignDetailState({}));
    };
  }, []);

  useEffect(() => {
    if (selectedLeadId) {
      setShowPropertyModal(true);
    }
  }, [selectedLeadId]);

  useEffect(() => {
    const payload = {
      id,
      selectedAttempt,
    };
    if (!id?.id) return;
    if (selectedAttempt === "" && listIdArray.length <=0) {
      dispatch(leadsAction(payload));
      dispatch(campaignDataAction(payload));
      dispatch(activityAction({ ...payload, index: 0, size: 20 }));
      dispatch(topMatrixAction(payload));
      dispatch(graphAction(payload));
      handleAttemptClose();
    }
  }, [id, selectedAttempt,listIdArray]);

  useEffect(() => {
    if (sectionData?.response?.data?.statusCode === 500) {
      navigate("/campaigns");
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: "Campaign does not exist!",
          type: ToastType.error,
        })
      );
    }
    if (sectionData?.response?.data?.statusCode === 404) {
      navigate("/campaigns");
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: "Campaign does not exist!",
          type: ToastType.error,
        })
      );
      dispatch(setTopSectionData({}));
    }
  }, [sectionData]);

  useEffect(() => {
    const newAttemptOptions = [];
    // for (let i = 1; i <= sectionData?.data?.totalAttempt; i++) {
    //   newAttemptOptions.push({ value: i, label: `Attempt ${i}` });
    // }

    for (let i = 0; i < sectionData?.data?.totalAttempt; i++) {
      const attempt = attemptsData[i];
      const zoneTime = ZONE_TIME.find(
        (zoneObj) => zoneObj?.zone === attempt?.zone
      );
      const labelText = zoneTime ? zoneTime.time_zone : "";
      const label = <span className="time-pill">{labelText}</span>;
      newAttemptOptions.push({
        value: i + 1,
        label: `Attempt ${i + 1}`,
        pill: label,
      });
    }

    setAttemptOptions(newAttemptOptions);

    attemptsData?.map((el: any, index: number) => {
      attemptIdMap.push({ value: index + 1, id: el?.id });
    });
    if (newAttemptOptions?.length > attemptsData?.length) {
      const difference = newAttemptOptions?.length - attemptsData?.length;
      for (let i = 1; i <= difference; i++) {
        attemptIdMap.push({ value: attemptsData?.length + i, id: "0" });
      }
    }
  }, [sectionData?.data?.totalAttempt, attemptsData]);

  useEffect(() => {
    let progress = calculateProgress(attemptsData ?? []);
    setCampaignProgress(progress);
  }, [attemptsData]);

  const handleAttemptSelect = (value: any) => {
    attemptIdMap?.map((el) => {
      if (el?.value === value?.value) {
        setSelectedAttempt(el?.id);
      }
    });
  };

  useEffect(() => {
    const leadData = leadsData?.map((lead: any) => {
      const date = getDateDifferenceInWords(new Date(lead?.createdAt), true);
      return {
        datecollected: date,
        lead: {
          title: `${firstName(lead?.name_first)} ${toCamelCase(
            lead?.name_last
          )}`,
          description: [lead?.street, ", ", lead?.city, ", ", lead?.state],
        },
        handleOnClicked: () => handleRowOnClick(lead.id),
        activityDrawerLeadId: false,
      };
    });
    setLeadsDetail(leadData ?? []);
  }, [leadsData]);

  useEffect(() => {
    const fetchlist = async () => {
      try {
        const response = await fetchListData(id);
        const data = response?.data;
        setListItems(
          data?.map((item: { id: string; name: string }) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchlist();
  }, []);

  useEffect(() => {
    const fbConversionApiSend = async () => {
      try {
        const payloadData = {
          data: [
            {
              event_name: "Campaign_Detail_view",
              event_time: Math.floor(Date.now() / 1000),
              event_source_url: window.location.href,
              action_source: "website",
              user_data: {
                client_ip_address: fetchIp,
                client_user_agent: navigator.userAgent,
                fbc: fbc,
                fbp: fbp,
                em: hashString(profileData.email),
                ph: hashPhoneNumber(profileData.phone),
                external_id: hashNumber(profileData?.data?.result?.organisationId),
              },
            },
          ],
        };
  
        await FbConversionApi(payloadData);
      } catch (error) {
        console.error('Error sending payload:', error);
      }
    };
    fbConversionApiSend();
  }, [profileData]);


  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="tabs-content">{children}</div>}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const [value, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      {/* <Header loggedIn></Header> */}
      <HeroSection
        title={sectionData?.data?.campaign}
        campaign={
          sectionData?.data?.campaignStatus
            ? sectionData?.data?.campaignStatus
            : ""
        }
      >
        <Tooltip
          title={
            profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
            PermissionListEnum.ORGANISATION_VA
              ? ROLE_PERMISSION_STATUS.NoAccess
              : ""
          }
        >
          <div>
            {profileData?.userRoleName ===
            PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
            PermissionListEnum.ORGANISATION_VA ? (
              <Button
                label="Cancel Campaign"
                className="outline"
                prefix={<SquareClose />}
                disabled={
                  profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VA
                }
              />
            ) : (
              <Button
                label="Cancel Campaign"
                className="outline"
                prefix={<SquareClose />}
                action={() => setOpen(true)}
                disabled={
                  !(
                    (
                      new Date(response?.data?.result[0]?.startDate) >
                      new Date(currentDate.toISOString().slice(0, -1))
                    )
                    // currentDate.toISOString()
                  ) || response?.data?.result[0]?.status === "cancelled"
                    ? true
                    : false
                }
              />
            )}
          </div>
        </Tooltip>

        <Tooltip
          title={
            profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
            PermissionListEnum.ORGANISATION_VA
              ? ROLE_PERMISSION_STATUS.NoAccess
              : ""
          }
        >
          <div>
            {profileData?.userRoleName ===
            PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
            PermissionListEnum.ORGANISATION_VA ? (
              <Button
                label="Cancel Campaign"
                className="outline"
                prefix={<SquareClose />}
                disabled={
                  profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER || profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VA
                }
              />
            ) : (
              <Button
                label="Edit Campaign"
                className="outline"
                prefix={<Edit />}
                action={() => {
                  dispatch(fetchProfileInfo({}));
                  setOpenEdit(true);
                }}
              />
            )}
          </div>
        </Tooltip>
      </HeroSection>
      {open && (
        <div>
          <CancelCampaign
            open={open}
            setOpen={setOpen}
            id={id}
            date={response?.data?.result[0]?.startDate}
          />
        </div>
      )}
      {openEdit && (
        <div>
          <EditCampaign openEdit={openEdit} setOpenEdit={setOpenEdit} id={id} />
        </div>
      )}

      <div className="wrapper">
        <div className={` ${styles.progress} dflex`}>
          <div className={` ${styles.progress__bar}`}>
            <p>Your campaign is {campaignProgress}% of the way there!</p>
            <BorderLinearProgress
              variant="determinate"
              value={campaignProgress}
            />
          </div>
          <div className={` ${styles.progress__button} dflex alignCenter`}>
            <Button
              label="Advanced Filter"
              className={`outline filter-icon ${focused ? `focused` : ``}`}
              prefix={<Filter />}
              action={handleFilterBtn}
              postfix={filterFlag ? <Circle color="#D92D20" /> : null}
            />
            {openAttemptFilter && (
              <FilterDropdown
                handleClose={() => {
                  handleAttemptClose();
                  setValue("lists", listIdArray);
                }}
                anchorEl={anchorAttempt}
              >
                <div className={`filterMenu`}>
                  <div className="filterMenu__info multiSelectText">
                    <DropDown
                      options={attemptOptions}
                      control={control}
                      name="attemptList"
                      label="Attempt"
                      placeholder={
                        sectionData?.data?.totalAttempt > 0
                          ? "Select an Attempt"
                          : "No Attempts"
                      }
                      handleOnChange={handleAttemptSelect}
                    ></DropDown>
                    <p>List</p>
                    <MultiSelectDropDown
                      options={listItems}
                      label="Select a list"
                      name="lists"
                      control={control}
                      emptySearchMetaData={{
                        icon: <SearchReflation />,
                        title: "No results found",
                      }}
                      placeholderTextStyle
                    />
                  </div>
                </div>
                <div className={`dflex filterMenuFooter`}>
                  <div className={`filterMenuFooter__half`}>
                    <Button
                      label="Reset"
                      className="full outline"
                      action={handleResetBtn}
                    />
                  </div>
                  <div className={`filterMenuFooter__half`}>
                    <Button
                      label="Apply"
                      className="primary full"
                      action={handleApplyBtn}
                      clicked={isAttemptSelected}
                      disabled={isAttemptSelected}
                    />
                  </div>
                </div>
              </FilterDropdown>
            )}

            {profileData?.userRoleName === PermissionListEnum.ORGANISATION_VIEWER ? 
               <Tooltip title={ROLE_PERMISSION_STATUS.NoAccess} placement="top">
                  <span>
                    <Button
                      label="Add Another Attempt"
                      className="primary"
                      prefix={<ClockForward />}
                      disabled
                    />
                  </span>
                </Tooltip>:!profileData?.organisation?.callbackPhone ? (
              <Button
                label="Add Another Attempt"
                className="primary"
                prefix={<ClockForward />}
                action={() => setOpenCallBackNoModal(true)}
              />
            ) : (
              <Button
                label="Add Another Attempt"
                className="primary"
                prefix={<ClockForward />}
                action={() => setOpenAddAnotherAttemptModal(true)}
              />
            )}
          </div>
        </div>
        <div className={`dflex ${styles.cards}`}>
          {cards?.map((card: any, i: number) => {
            return (
              <div className={`${styles.cards__col}`} key={card.title + i}>
                <AnalyticCards
                  key={card.title + i}
                  title={card?.title}
                  value={formartNumberAsLocalString(
                    topMatrixData?.data?.[card?.key] ?? 0
                  )}
                  statusToday={card?.statusToday}
                  status={
                    topMatrixData?.data?.[card?.statusKey] &&
                    `${formartNumberAsLocalString(
                      topMatrixData?.data?.[card?.statusKey] || 0
                    )} in the last day`
                  }
                  showStatusKey={Boolean(
                    Number(topMatrixData?.data?.[card?.statusKey]) > 0
                  )}
                ></AnalyticCards>
              </div>
            );
          })}
        </div>
        <div className={`dflex ${styles.callAndActivity}`}>
          <div className={` ${styles.callAndActivity__call}`}>
            <h3>Call Tracker</h3>
            <p>Track the delivery of your campaign over time.</p>
            <Chart options={options} series={series} type="area"></Chart>
          </div>
          <div className={` ${styles.callAndActivity__activity}`}>
            <h2>Activity</h2>

            {isActivityLoading ? (
              <div className="table-loader">
                <Loader></Loader>
              </div>
            ) : (
              <div className={` ${styles.callInfo} `} id="scrollableDiv1">
                <InfiniteScroll
                  dataLength={activityData?.length ?? ""}
                  next={() =>
                    handleNextActivityPage && handleNextActivityPage()
                  }
                  hasMore={activityData?.length < ActivityTotal}
                  scrollableTarget="scrollableDiv1"
                  loader={
                    <div className="table-loader">
                      <Loader></Loader>
                    </div>
                  }
                >
                  <ul>
                    {activityData?.length ? (
                      activityData?.map((card: any, i: number) => {
                        const date = getDateDifferenceInWords(
                          new Date(card?.createdAt),
                          true
                        );
                        return (
                          <li>
                            <figure>
                              <Call />
                            </figure>
                            <figcaption>
                              <h5>
                                {firstName(card?.name_first)}{" "}
                                {toCamelCase(card?.name_last)}{" "}
                                <span>{date}</span>
                              </h5>
                              <p style={{ textTransform: "capitalize" }}>
                                {card?.street}, {card?.city}, {card?.state}{" "}
                              </p>
                            </figcaption>
                          </li>
                        );
                      })
                    ) : (
                      <EmptyPlaceholder
                        icon={<SearchReflation />}
                        title="No results found"
                        smallSize
                      />
                    )}
                  </ul>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>

        <div className={` ${styles.leadsTab}`}>
          <Tabs value={value} onChange={handleTabChange} variant="scrollable">
            <Tab label="New Leads" {...a11yProps(0)} />
            <Tab label="Hung Up" {...a11yProps(1)} />
            <Tab label="Voice Mail" {...a11yProps(2)} />
            <Tab label="Dead" {...a11yProps(3)} />
            <Tab label="Wrong Number" {...a11yProps(4)} />
            <Tab label="DNC" {...a11yProps(5)} />
            <Tab label="Failed" {...a11yProps(6)} />
          </Tabs>

          <CustomTabPanel value={value} index={0}>
            <div className={`${styles.lead}`}>
              <div
                className={`dflex justifySpaceBetween alignCenter ${styles.lead__header}`}
              >
                <div>
                  <h2 className="flex alignCenter">
                    Leads{" "}
                    {total > 0 && (
                      <span
                        className={`inlineFlex justifyCenter alignCenter ${styles.pill}`}
                      >
                        {/* {total} Leads */}
                        {total === 1 ? `1 Lead` : `${total} Leads`}
                      </span>
                    )}
                  </h2>
                  <p>See a list of your collected leads.</p>
                </div>
                <div className={`dflex ${styles.buttonRight}`}>
                  {/* <Button
                label="Export to CSV"
                className="outline"
                prefix={<Cloud />}
                action={downloadCSV}
              /> */}

                  <ExportToCsv
                    headers={LeadsDetail_columns.map((column) => {
                      return { id: column.key, displayName: column.label };
                    })}
                    filename={`Leads.csv`}
                    loadData={() => handleChange()}
                    label="Export to CSV"
                    loading={exportListLoading}
                  ></ExportToCsv>
                  <Button
                    label="Go to Lead Manager"
                    className="primary"
                    prefix={<ArrowUp />}
                    action={() => {
                      navigate(`/leads?campaign=${id?.id}`);
                    }}
                  />
                </div>
              </div>
              <div
                className={`dflex justifySpaceBetween alignCenter ${styles.lead__filters}`}
              >
                {/* <div className={`${styles.filtersTabs}`}>
              <ul>
                <li onClick={() => handleChangeOnLeadsFilter("")}>
                  <span
                    className={`${
                      filter.leadFilterBy === "" ? styles.active : ""
                    }`}
                    role="link"
                  >
                    View all
                  </span>
                </li>
                <li onClick={() => handleChangeOnLeadsFilter("call")}>
                  <span
                    className={`${
                      filter.leadFilterBy === "call" ? styles.active : ""
                    }`}
                    role="link"
                  >
                    Phone
                  </span>
                </li>
                <li onClick={() => handleChangeOnLeadsFilter("sms")}>
                  <span
                    className={`${
                      filter.leadFilterBy === "sms" ? styles.active : ""
                    }`}
                    role="link"
                  >
                    SMS
                  </span>
                </li>
              </ul>
            </div>  */}
                <div className={`${styles.search}`}>
                  <SearchBar
                    searchText={searchLeadsText}
                    onChange={handleLeadsSearch}
                    // dataTestId='campaignSearchTestId'
                  />
                </div>
              </div>
              <div className={` ${styles.lead__list}`}>
                {isLeadsLoading ? (
                  <div className="table-loader">
                    <Loader></Loader>
                  </div>
                ) : (
                  <CustomTable
                    rows={leadsdetail}
                    columns={Leads_COLUMN}
                    hasMore={leadsData?.length < total}
                    tableStriped
                    currentPageIndex={1}
                    emptyTableMetaData={{
                      icon: <SearchReflation />,
                      title: "No results found",
                      description:
                        "We couldn’t find any results that match your search or filtering criteria.",
                    }}
                    sortDirection={filter.sortDirection}
                    sortKey={filter.sortKey}
                    handleNextPage={handleNextPage}
                    handleSorting={arrowClick}
                  ></CustomTable>
                )}
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <HungUp />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <VoiceMail />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={3}>
            <Dead />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <WrongNumber />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Dnc />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <Failed />
          </CustomTabPanel>
        </div>
      </div>

      <AddAnotherAttempt
        open={openAddAnotherAttemptModal}
        handleClose={() => setOpenAddAnotherAttemptModal(false)}
        campaignId={id}
        displayErrorMessage={displayErrorMessage}
        displaySuccessMessage={displaySuccessMessage}
        opUpgradeNow={opUpgradeNow}
      ></AddAnotherAttempt>
      <UpgradeModal
        handleClose={() => {
          setOpenPlanUpgradeModal(false);
          setUpgradSubscriptionProduct(null);
        }}
        open={openPlanUpgradeModal}
        product={upgradSubscriptionProduct}
        parentType={UpgradeModalParentType.AnotherAttempt}
        minCreditCount={propertyCountByCampaign.requiredCredit}
      ></UpgradeModal>
      {openCallBackNoModal && (
        <CallBackNo
          open={openCallBackNoModal}
          closeModal={() => setOpenCallBackNoModal(false)}
        ></CallBackNo>
      )}
      {selectedLeadId && (
        <CustomModal open={showPropertyModal} handleClose={handlePropertyModal}>
          <PropertyDetails
            displayText={"Campaign Detail"}
            selectedPropertyId={selectedLeadId}
            handleModalClose={handlePropertyModal}
            type={PropertyDetailsParentIdType.LEAD_ID}
          />
        </CustomModal>
      )}
    </>
  );
};
export default CampaignDetail;
