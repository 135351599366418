import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import { fetchLeadListDetail } from "../../organisms/listsTable/sagaActions";
import { getcallAttemptsCount, getLeadsListDetail, getListDetail, getListDetailLoading } from "../../organisms/listsTable/selectors";
import PropertyCard from "../../organisms/propertySearchFilter/PropertyCard";
import styles from "./ListDetail.module.scss";
import CustomModal from "../../components/customModal/CustomModal";
import PropertyDetails from "../property/PropertyDetails";
import { PropertyDetailsParentIdType } from "../property/property.enum";
import CustomCarousel from "../../components/customSlider/CustomCarousel";
import ArrowLeft from "../../assets/icons/arrowLeft";
import {  ArrowRightIcon } from "@mui/x-date-pickers";
import { setSelectedLeadId } from "../../organisms/leadsTable/reducer";
import Button from "../../atoms/Button";
import SearchReflation from "../../assets/icons/searchReflation";
import Funnel from "../../assets/icons/funnel";
import Circle from "../../assets/icons/circle";
import FilterDropdown from "../../organisms/filterDropdown/FilterDropdown";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDownV2";
import { useForm } from "react-hook-form";

const ListDetail = () => {
  const param = useParams();
   const { reset, control, getValues,setValue } = useForm({
      mode: "onSubmit",
      defaultValues: {
        callAttempts: [],
      },
    });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listDetailRes = useSelector((state) => getLeadsListDetail(state));
  const listDetail = useSelector((state) => getListDetail(state));
  const listDetailLoading = useSelector((state) =>getListDetailLoading(state));
  const callAttemptCount = useSelector((state) =>getcallAttemptsCount(state));
  const [propertyId, setPropertyId] = useState("");
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [propertyIndex,setPropertyIndex]=useState(-1)
  const [filterFlag, setFilterFlag] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [callAttemptsCount, setCallAttemptsCounts] = useState([]);
  const filterButtonRef = useRef<HTMLElement | null>(null);
  const [showfilterBtnDropdown, setShowFilterBtnDropdown] =
  useState<boolean>(false);

  const [filter, setFilter] = useState<{
    skip: number;
    count: number;
    vaCallAttempt?: string[];
  }>({
    skip: 1,
    count: 11,
    vaCallAttempt: [],
  });
  
  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, skip: prev?.skip + 1 }));
  };

  const handlePropertyClick = (property: any) => {
    setSelectedProperty(property);
    setPropertyId( property?.leadId);
    dispatch(setSelectedLeadId(property?.leadId));
  };

  const handlePropertyModal = () => {
    setShowPropertyModal(false);
    setPropertyId("");
  };

  const handleNextProperty=()=>{
const currentIndex = listDetailRes.findIndex((item:any) => item.leadId === propertyId); 
  if (currentIndex < listDetailRes.length - 1) {
    setPropertyId(listDetailRes[currentIndex + 1].leadId); 
  } 
  }

  const handlePrevProperty=()=>{
    const currentIndex = listDetailRes.findIndex((item:any) => item.leadId === propertyId);
    if (currentIndex > 0) {
      setPropertyId(listDetailRes[currentIndex - 1]?.leadId);
    } 
  }
 const handleFilterButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    filterButtonRef.current = e.currentTarget;
    setShowFilterBtnDropdown(true);
  };

  const handleAttemptClose = () => {
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
    setApplyClicked(false);
  };

  const handleAttemptApplyClose = () => {
    setApplyClicked(true);
    if (
      getValues("callAttempts").length !== 0 
    ) {
      setFilterFlag(true);
    }
    setFilter({ ...filter, skip: 1,vaCallAttempt: getValues("callAttempts") });
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
  };


  useEffect(() => {
    const options = callAttemptCount.filter((list: any) => list.vaAttemptCount !== 0).map((list: any) => ({
        value: list.vaAttemptCount,
        label: list.vaAttemptCount,
    }));
    setCallAttemptsCounts(options);
}, [callAttemptCount]);

  useEffect(() => {
    if (!param.id) return;
    if (!showPropertyModal) {
      const payload = { id: param.id, filter };
      dispatch(fetchLeadListDetail(payload));
    }
  }, [dispatch, param.id, filter, showPropertyModal]);

  useEffect(() => { 
    if (propertyId) {
      setShowPropertyModal(true);
    }
  }, [propertyId]);

  useEffect(() => {
    if (!showPropertyModal) {
      setPropertyId("");
    }
  }, [showPropertyModal])

  useEffect(() => {
    if (Object.keys(listDetailRes).length > 0) {
      const index = listDetailRes.findIndex((item:any) => item?.leadId === propertyId);
      setPropertyIndex(index)
      if (index === 9 && listDetailRes?.length < listDetail?.totalCount) {
        handleNextPage()
      } 
    }
  }, [propertyId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedLeadId(""));
    };
  }, []);

  useEffect(() => {
    if (!showfilterBtnDropdown && !applyClicked) {
      reset({
        callAttempts: [],

      });
    }
  }, [showfilterBtnDropdown, applyClicked]);
  
  return ( 
    <>
    
   {listDetailLoading ? <div className={` ${styles.listDetailLoader}`}>
                <Loader></Loader>
              </div> : <div className={` ${styles.listDetail}`}>
        <div className={` ${styles.listDetail__header}`}>
        <ul className="flex">
            <li>
            <span
                role="link"
                className={` ${styles.propertyHeader__leftBack} flex alignCenter`}
                onClick={() => navigate(`/lists`) }
              >
                <ArrowLeft />
                Lists
              </span> 
              </li>
              <li><ArrowRightIcon/></li>
              <li>{listDetail?.name}</li>
           
        </ul>
    
        {/* <h2>{listDetail?.name}</h2> */}
     
        </div>
       {Object.keys(callAttemptCount).length > 1 && 
       <div className={` ${styles.listDetail__filter} dflex justifyEnd`}>
       <Button
                label="Filter"
                className="outline filter-icon"
                prefix={<Funnel />}
                action={handleFilterButtonClick}
                postfix={filterFlag ? <Circle color="#D92D20" /> : null}
              />
</div>              }
              {showfilterBtnDropdown && (
                <FilterDropdown
                  handleClose={handleAttemptClose}
                  anchorEl={filterButtonRef.current}
                >
                  <div
                    className={`filterMenu`}
                  >
                    <div className="filterMenu__info">
                      <div
                        className={`filterMenu__list`}
                        data-testid="SelectStatusTestId"
                      >
                        <p>Call Attempts</p>
                        <MultiSelectDropDown
                          options={callAttemptsCount}
                          label="Select call attempts"
                          control={control}
                          name="callAttempts"
                          emptySearchMetaData={{
                            icon: <SearchReflation />,
                            title: "No results found",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`dflex filterMenuFooter`}>
                    <div className={`filterMenuFooter__half`}>
                      <Button
                        label="Reset"
                        className="outline full"
                        action={() => {
                          setCallAttemptsCounts([])
                          reset({
                            callAttempts: [],
                          });
                          setFilter({
                            skip: 1,
                            count: 11,
                            vaCallAttempt: [],
                          });
                          setFilterFlag(false);
                          setShowFilterBtnDropdown(false);
                          
                        }}
                      />
                    </div>
                    <div className={`filterMenuFooter__half`}>
                      <Button
                        label="Apply"
                        className="primary full"
                        action={handleAttemptApplyClose}
                        dataTestId={"ApplyFilterBtnId"}
                      />
                    </div>
                  </div>
                </FilterDropdown>
              )}
        <div>
          <InfiniteScroll
            dataLength={listDetailRes?.length}
            next={()=>handleNextPage()}
            hasMore={listDetailRes?.length < listDetail?.totalCount}
            // scrollableTarget="scrollableDiv1"
            loader={
              <div className="table-loader w-full">
                <Loader></Loader>
              </div>
            }
          >
            <div className={` ${styles.listDetail__list} dflex`}>
            {listDetailRes?.map((property: any, index: number) => {
              return (
                <div className={` ${styles.listDetail__card} dflex`}>
                  <PropertyCard
                    property={property}
                    handleMouseHover={() => {}}
                    handleMouseLeave={() => {}}
                    selectedProperty={selectedProperty}
                    handlePropertyClick={handlePropertyClick}
                    propertiesInView={[]}
                    status={property?.leadStatus}
                    callAttempts={property?.vaCallAttempt}
                  ></PropertyCard>
               </div>
              );
            })}
             </div>
          </InfiniteScroll>
        </div> 
        {propertyId && (
            <CustomModal
              open={showPropertyModal}
              handleClose={handlePropertyModal}
              customClass="list-carousel"
            >
                  <CustomCarousel
                  handleNextProperty={handleNextProperty}
                  handlePrevProperty={handlePrevProperty}
                  listDetailRes={listDetailRes}
                  propertyIndex={propertyIndex}
                  >
              <PropertyDetails
                selectedPropertyId={propertyId}
                displayText={listDetail?.name}
                handleModalClose={handlePropertyModal}
                type={PropertyDetailsParentIdType.LEAD_ID}
              />
             </CustomCarousel>
            </CustomModal>
          )}
      </div>}
      </>
  );
};

export default ListDetail;
