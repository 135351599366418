export const SUBSCRITPTION_STATUS = {
  NoGeneralAccess: `You don't have platform access. Please head to the "Billing" tab and hit "Upgrade Now". `,
  CardDecline: `You don't have platform access because your account is overdue. Please update your credit card. `,
  DisableUser: `Your account has been disabled. Try connecting with your organization owner or create a new organization`,
  NoGeneralAccessNewUser: `You don't have platform access. Please contact your organisation owner.`,
};

export const ROLE_PERMISSION_STATUS = {
  NoAccess: `You don't have permission to access this.`,
};

