export const VA_HOURS_CONSTANTS:any = {
    2500: 40,
    5000: 80,
    7500: 120,
    10000: 160, 
  };

  export const VA_CALLING_DAYS_CONSTANTS:any = {
    2500: 5,
    5000: 10,
    7500: 15,
    10000: 20, 
  };

  export const VA_CALLING_WEEK_CONSTANTS:any = {
    2500: 1,
    5000: 2,
    7500: 3,
    10000: 4, 
  };