import { DropDownOption } from "../../models/dropDown.interface";
import { CampaignSteps } from "./enum";
import { CampaignFormModel } from "./interface";

export const DEFAULT_CAMPAIGN_STEPS_VALUES: CampaignFormModel = {
  [CampaignSteps.SelectList]: {leadList:[],listNames:[]},
  [CampaignSteps.StartCampaign]: {
    campaignName: "",
    startDate: "",
    dialsPerNumber: "",
    maxAttemptPerNumber: "",
    optimizeCampaign: true,
    callbackPhone:"",
    voiceId:""
  },
};

export const MAX_ATTEMPTS_OPTIONS: DropDownOption[] = [
  { value: 3, label: "3 attempts" },
  { value: 4, label: "4 attempts" },
  { value: 5, label: "5 attempts" },
  { value: 6, label: "6 attempts" },
  { value: 7, label: "7 attempts" },
  { value: 8, label: "8 attempts" },
  { value: 9, label: "9 attempts" },
  { value: 10, label: "10 attempts" },
  { value: 11, label: "11 attempts" },
  { value: 12, label: "12 attempts" },
  { value: 13, label: "13 attempts" },
  { value: 14, label: "14 attempts" },
  { value: 15, label: "15 attempts" },
];

export const DPN: DropDownOption[] = [
  { value: 3, label: "Dials 3" },
  { value: 4, label: "Dials 4" },
  { value: 5, label: "Dials 5" },
  { value: 6, label: "Dials 6" },
  { value: 7, label: "Dials 7" },
  { value: 8, label: "Dials 8" },
  { value: 9, label: "Dials 9" },
  { value: 10, label: "Dials 10" },
  { value: 11, label: "Dials 11" },
  { value: 12, label: "Dials 12" },
  { value: 13, label: "Dials 13" },
  { value: 14, label: "Dials 14" },
  { value: 15, label: "Dials 15" },
];